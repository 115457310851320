





























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { User, Props, Global } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import PropUtils from '@/modules/PropUtils';
import PropEditor from '@/components/PropEditor.vue';
import { Types } from '@/modules/API';
import TextFieldDialog from '@/components/TextFieldDialog.vue';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';

@Component({ components: {
    TextFieldDialog,
    MessageDialog,
    PropEditor
}})
export default class MetaDeviceFilter extends Vue {
    @User.State('project') project;
    @User.State('username') username;
    @Props.Action('load') loadProjectProperties;
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;
    @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
    @Global.State('readonly_user') readonly_user;

    @Prop() selectedDeviceType;
    @Prop() deviceTypes;
    @Prop() search_fields;
    @Prop() showClearBtn;
    @Prop() showTemplate;
    @Prop() searchBtnText;
    @Prop() templates_prop_name;
    @Prop() report_templates_property;

    selectedFields = [];
    searchFields = [];
    info = 'You can choose up to 4 fields';
    info_color = 'black';
    filter = {};
    type = {text: '', value: ''};
    saveTempDialog = false;
    template_name = '';
    messages = '';
    isMessageDialogOpen = false;

    mounted(){
        this.initValues();
    }

    @Watch('search_fields')
    initValues(){
        const fields_options = this.selectedDeviceType.value === 'tondo' 
            ? this.fixture_meta_device_options 
            : this.cabinet_meta_device_options;
        this.selectedFields = [];
        this.searchFields = PropUtils.getUppercaseFieldsList(fields_options);
        Object.keys(fields_options).forEach((field_name) => Vue.set(this.filter, field_name, { selected: [], search: '' }));
        this.type = this.selectedDeviceType;
    }

    checkValue(data, field){
        if (data.value.length && !field.options.includes(data.value.slice(-1)[0])){
            Vue.set(this.filter, field.value, {selected: data.value.slice(0, -1), search: ''});
        }else {
            Vue.set(this.filter[field.value], 'search', '');
        }
    }

    removeSelectedFilter(index){
        const field_name = this.selectedFields[index].value;
        Vue.set(this.filter, field_name, {selected: [], search: ''});
        this.selectedFields = this.selectedFields.slice(0, index).concat(this.selectedFields.slice(index + 1));
        this.updateInfoColor();
    }

    saveTemplateClicked(){
        this.selectedFields.some((field) => this.filter[field.value].selected.length) 
            ? this.saveTempDialog = true
            : this.isMessageDialogOpen = true;
    }

    saveAsTemplate(template_name){
        this.template_name = template_name;
        if (!this.template_name){
            this.messages = 'Reqired Field';
            return;
        }

        this.template_name = this.template_name.trim();
        const is_exist = this.checkIsNameExist();
        if (is_exist) {
            this.messages = 'Name Already Exist';
            return;
        }

        this.messages = '';
        const property_details = {
            name: this.templates_prop_name,
            objectID: this.project.id,
            objectType: Types.PROJECTS
        };
        const new_template = {
            name: this.template_name,
            type: this.type.value,
            values: this.selectedFields.reduce((acc, curr) => {
                if (this.filter[curr.value].selected.length) acc[curr.value] = this.filter[curr.value].selected.map((field) => field.value);
                return acc;
            }, {})
        };
        
        const property_value = cloneDeep(this.report_templates_property);
        property_value[this.username]['inventory'].push(new_template);
        (this.$refs.propEditor as PropEditor).save(
            property_value,
            property_details,
            () => this.loadProjectProperties({ projectId: this.project.id }),
            'The report was successfully saved'
        );
        this.saveTempDialog = false;
    }

    checkIsNameExist(){        
        return this.report_templates_property[this.username].inventory.some((template) => template.name.toLowerCase() === this.template_name.toLowerCase());
    }

    searchDevices(){
        const fields = Object.entries(this.filter).filter(([field_name, data]) => data['selected'].length || data['search']).map(([field_name, data]) => {
            const selected = data['selected'].map((field) => field.value);
            return (!selected.length)
                ? {name: field_name, selected: [data['search']]}
                : selected.length && data['search'] 
                ? {name: field_name, selected: [...selected, data['search']]}
                : {name: field_name, selected: [...selected]};
        });
            
        this.$emit('searchChanged', fields);
    }

    selectedFieldsChanged(){
        if (this.selectedFields.length === 1) this.$emit('searchClicked', false);
        this.updateInfoColor();

        if (this.selectedFields.length > 4) {
            this.selectedFields = this.selectedFields.slice(0, -1);
        }
    }

    @Watch('type')
    updateData(){
        const fields_options = this.type.value === 'tondo' 
            ? this.fixture_meta_device_options
            : this.cabinet_meta_device_options;

        this.selectedFields = [];
        this.searchFields = PropUtils.getUppercaseFieldsList(fields_options);
        Object.keys(fields_options).forEach((field_name) => Vue.set(this.filter, field_name, { selected: [], search: '' }));
        this.updateInfoColor();
        this.$emit('typeChanged', this.type);
    }

    updateInfoColor(){
        this.info_color = this.selectedFields.length > 4 
            ? 'red'
            : 'black';    
    }

    get device_types(){
        return this.deviceTypes.map((device_type) => ({...device_type, text: this.$t(device_type.text)}));
    }

    removeFieldChip(item) {
        const item_index = this.selectedFields.indexOf(item);
        Vue.set(this.filter, this.selectedFields[item_index].value, {selected: [], search: ''});

        this.selectedFields = this.selectedFields.length === 1 ? [] : this.selectedFields.slice(0, item_index).concat(this.selectedFields.slice(item_index + 1));

        const filter_select = this.$refs.filter_select;
        if (filter_select) filter_select['blur']();
        if (this.search_fields.length) this.$emit('clearClicked');

    }

    // removeFilterChip(field_name, item) {
    //     const item_index = this.filter[field_name].selected.indexOf(item);
    //     this.filter[field_name].selected = this.filter[field_name].selected.slice(0, item_index).concat(this.filter[field_name].selected.slice(item_index + 1));
    // }

    checkFilterValueSelected(field_name, item){
        return this.filter[field_name].selected.includes(item);
    }

}

