






















































































































































































































































































import Vue from 'vue';
import vuex from '@/store';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import MQTT from '@/modules/MQTT';
import { Global, Groups, User, Reports, Props } from '@/store';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import ControlTab from '@/pages/groups/GroupTabs/ControlTab.vue';
import MultipleSearchFilter from '@/components/MultipleSearchFilter.vue';
import DimmingDialog from '@/components/dialogs/DimmingDialog.vue';
import LoadingDialog from '@/components/dialogs/LoadingDialog.vue';
import cloneDeep from 'lodash/cloneDeep';
import ExportData from '@/components/ExportData.vue';
import { setDeviceProperty } from '@/modules/ApiUsers';
import API, { Types } from '@/modules/API';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

@Component({
  components: {
    MultipleSearchFilter,
    ExportData,
    ControlTab,
    DimmingDialog,
    LoadingDialog,
    ConfirmDialog
  }
})
export default class ManageSelectedDevices extends Vue {
  @Prop() devices;
  @Prop() groupDevices;
  @Prop() createMode;
  @Prop() group;

  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Groups.Action('edit') edit;
  @Groups.Action('create') create;
  @Groups.State('list') groups;
  @User.State('project') project;
  @User.State('isManager') isManager;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;
  @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
  @Props.State('list') projectProperties;

  groupName: string = '';
  groupDescription: string = '';
  mqtt = MQTT.instance;
  search = '';
  cabinet_search = '';
  new_cabinet_message = `I know that this ID doesn't exist in the system and I'm responsible for the consequences`;
  show_new_message = false;
  allChecked = false;
  isChecked = {};
  nameError: any = [];
  saveError: string = '';
  loading = true;
  filterDialog = false;
  deviceTypes = ['tondo'];
  filter_fields = [];
  checkedDevices = null;
  rowsPerPageItems = [5, 10, 20];
  pagination = { rowsPerPage: 5 };
  tab = 'main';
  exportFields = null;
  exportedData = null;
  allDevicesData = null;
  fields = null;
  insertFieldsOptions = null;
  inputs = null;
  err = null;
  PropUtils = PropUtils;
  selectedGroup = null;
  dimmingDialog: boolean = false;
  dimming_converting_table = null;
  converted: boolean = false;
  percentToDaliTable: any = null;
  controlled_devices: any[] = [];
  loadingDialog: boolean = false;
  prop_utils = PropUtils;
  mode = -1;
  confirmDialog = false;
  devices_id_options = [];
  cabinets_id_options = [];
  optional_cabinet_id_options = [];
  polygon_export_fields_prop = null;
  select_all = false;

  rules = {
    required: (value) => !!value || 'Required.'
  };

  mounted() {
    this.loading = true;
    this.filter_fields = [];
    this.setIsChecked();
    this.setCabinetIdOptions();

    const insertedFields = this.$store.state.Props.list.find((prop) => prop.name === 'dashboard.form_meta_device');
    if (insertedFields && insertedFields.hasOwnProperty('value') && insertedFields.value.hasOwnProperty('fields')) {
      this.insertFieldsOptions = insertedFields.value.fields;
      const inputs = {};
      this.insertFieldsOptions.forEach((field) => inputs[field.name] = '');
      this.inputs = inputs;
    }
    this.polygon_export_fields_prop = PropUtils.getProjectPropertyData(this.projectProperties, 'dashboard.polygon_export_fields');
    this.loading = false;
  }

  setCabinetIdOptions(){
    this.devices_id_options = this.fixture_meta_device_options.cabinet_id || [];
    this.cabinets_id_options = this.cabinet_meta_device_options.cabinet_id || [];
    this.updateOptionalCabinetIds();
  }

  @Watch('cabinet_search')
  updateCabinetID(){
    this.show_new_message = !this.cabinet_search ? false : this.checkNewCabinetID();
  }

  checkNewCabinetID(){
    return !this.optional_cabinet_id_options.includes(this.cabinet_search);
  }

  updateOptionalCabinetIds(){
    this.optional_cabinet_id_options = [...new Set(this.devices_id_options.concat(this.cabinets_id_options))];
    this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
    this.optional_cabinet_id_options.push('');
  }

  get deviceModes(){
    return [
      { text: this.$t('Maintenance'), value: -1},
      { text: this.$t('In Installation'), value: -3},
      { text: this.$t('Fault In Care'), value: -9}
    ];
  }

  get title() {
    return this.tab === 'main'
      ? 'Manage Selected Devices'
      : this.tab === 'choose'
      ? 'Select an Action'
      : this.tab === 'export'
      ? 'Select Exported Fields'
      : this.tab === 'insert'
      ? 'Add Inserted Field Values'
      : this.tab === 'command'
      ? 'Selected Command'
      : this.tab === 'group'
      ? 'Add Group Details'
      : this.tab === 'status'
      ? 'Updade Device Status'
      : '';
  }

  get buttonText() {
    return 'Set';
  }

  async handleGroupAction() {
    if (!this.groupName) {
      this.nameError = ['Name is required'];
      return;
    }
    if (!this.groupDevices.size) {
      vuex.dispatch('Global/throwNotify', {
        type: 'error',
        title: `${this.$t('Error')}!`,
        text: this.$t(`You must choose at least one device`)
      });
      return;
    }
    if (this.groups.length) {
      if (
        this.groups.some(
          (group) =>
            group.name.trim().toLowerCase() ===
            this.groupName.trim().toLowerCase()
        )
      ) {
        this.nameError = ['Name already exists'];
        return;
      }
      // this.$router.push('/groups');
    }
    const devicesMapping = [...this.groupDevices.keys()].map(
      (device_id) => `${device_id}.power`
    );
    await this.createGroup(devicesMapping);
    this.sendDeviceCommand();
    this.tab = 'main';
    // this.$router.push('/groups');
  }

  async handleChangeClicked(){
    this.confirmDialog = false;
    this.loadingDialog = true;

    const selected_devices = Array.from(this.groupDevices.keys());
    const slices = Math.ceil(selected_devices.length / 100);
    let startIndex = 0, endIndex = Math.min(selected_devices.length, 100);
    for (let i = 0; i < slices; i++){
      const current = selected_devices.slice(startIndex, endIndex);
      await Promise.all(current.map( async (device_id) => await this.updateDeviceMode(device_id, this.mode)));
      startIndex = endIndex;
      endIndex = Math.min(selected_devices.length, endIndex + 100);
    }

    this.loadingDialog = false;
  }

  updateDeviceMode(device_id, mode){
    return API.post('', `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${device_id}/streams/sys___active/evt/`, { content: mode }, {}, 'v4');
  }

  notifyStatus(isCompleted){
    const notifyData = isCompleted
      ? {
        text: 'Devices mode updated successfully!',
        type: 'success',
        title: 'Success!'
      } : {
        text: 'Some mode devices could not update. Please try again later.',
        type: 'error',
        title: 'Error!'
      };
    
    this.$notify(notifyData);
  }

  @Watch('filter_fields')
  get devicesList() {
    const prevDevicesIds = [];
    for (const key in this.isChecked) {
      if (this.isChecked[key]) {
        prevDevicesIds.push(key);
      }
    }
    const prevDevices = [];
    for (const i of prevDevicesIds) {
      prevDevices.push(this.devices.find((dev) => dev.id === i));
    }

    let currentDevices = this.filter_fields.length
      ? this.getDevicesByFilter()
      : this.devices;
    currentDevices = currentDevices.filter(
      (dev) => prevDevicesIds.indexOf(dev.id) < 0
    );
    currentDevices = currentDevices.concat(prevDevices);

    const devicesBySearch = currentDevices.filter((v) =>
      v.name.toLowerCase().includes(this.search.toLowerCase())
    );
    this.allChecked = devicesBySearch.length === this.groupDevices.size;
    if (this.allChecked) {
      for (const index of currentDevices) {
        this.isChecked[index.id] = true;
      }
      this.checkedDevices = currentDevices;
    }
    return devicesBySearch.sort((device1, device2) =>
      device1.name.localeCompare(device2.name)
    );
  }

  getDevicesByFilter() {
    let filter_devices = cloneDeep(this.devices);
    this.filter_fields.forEach((field) => {
      filter_devices = filter_devices.filter((device) => {
        const field_value = this.commissioned_map.get(device.id)['meta.device'][field.name];
        if (!field_value) return false;
        return field.selected.includes(field_value.toString());
      });
    });
    return filter_devices;
  }

  setFilterDialogOpen() {
    this.filterDialog = !this.filterDialog;
  }

  selectField() {
    this.select_all = this.exportFields.every((export_field) => export_field.selected);
    this.setExportedData();
  }

  selectAllExportFields(){
    this.exportFields.forEach((export_field) => export_field.selected = this.select_all);
    this.setExportedData();
  }

  clearPolygonData(){
    this.$emit('clearPolygonData');
  }

  async handleDevicesEdit() {
    this.err = '';
    const inputs = { ...this.inputs };
    for (const field in inputs) {
      if (inputs[field].length === 0) {
        delete inputs[field];
      } else {
        inputs[field] = inputs[field].trim();
      }
    }
    if (Object.keys(inputs).length === 0) {
      this.err = 'You have to insert at least one field value';
      return;
    }
    for (const f in inputs) {
      const fieldOption = this.insertFieldsOptions.find((option) => option.name === f);
      if (fieldOption.type === 'number') {
        const value = parseInt(inputs[f], 10);
        if (isNaN(value)) {
          this.err = `The value of ${f} should be a valid number`;
          return;
        } else {
          if (
            fieldOption.hasOwnProperty('range') &&
            (value < fieldOption.range.min || value > fieldOption.range.max)
          ) {
            this.err = `The value of ${f} is not in range`;
            return;
          }
        }
      }
    }
    if (this.err.length === 0) {
      let notifySuccess = false;
      Object.entries(inputs).map(([field, value]) => inputs[field] = Utils.removeLeadingZeroesAndSpaces(value));
      const devices = Array.from(this.groupDevices.values());
      try {
        for (const device of devices) {
          let metaDevice = device['meta.device'];
          if (metaDevice.hasOwnProperty('fixture_icon') && metaDevice['fixture_icon'] === 'Virtual') {
            metaDevice = { ...metaDevice, ...inputs, fixture_icon: 'Virtual' };
            this.$notify({
              type: 'error',
              title: 'Warning',
              text: 'Virtual device icon can not be changed'
            });
          } else {
            metaDevice = { ...metaDevice, ...inputs };
            notifySuccess = true;
          }
          metaDevice = PropUtils.getUpdatedUppercaseFields(metaDevice);
          const response_ok = await setDeviceProperty(device['id'], 'meta.device', metaDevice);
          if (response_ok){
            vuex.commit('Reports/editDeviceField', {device_id: device['id'], name: 'meta.device', value: metaDevice});
          }
        }
        if (
          notifySuccess ||
          Object.keys(inputs).length > 1 ||
          Object.keys(inputs)[0] !== 'fixture_icon'
        ) {
          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'All changes have been saved successfully'
          });
        }
        this.$emit('changeDevicesData');
        this.tab = 'choose';
      } catch (e) {
        console.log(e);

        this.$notify({
          type: 'error',
          title: 'Warning',
          text: 'Something went wrong'
        });
      }
    }
  }

  @Watch('checkedDevices')
  setcheckedDevices() {
    if (this.checkedDevices.length === 0) {
      this.tab = 'main';
      return;
    }

    const exported = [], allDevices = [];

    if (this.polygon_export_fields_prop) {
      this.polygon_export_fields_prop.forEach((field) => {
        if (field.hasOwnProperty('inner_fields') && Object.keys(field.inner_fields).length) {
          Object.entries(field.inner_fields).forEach(([inner_field_name, data]) => {
            const text_field = data[this.lang];
            exported.push({field: text_field, selected: data['selected'], field_name: field.name, inner: inner_field_name});
          });
        }else {
          exported.push({field: field[this.lang], selected: field.selected, field_name: field.name});
        }
      });
      this.exportFields = [...exported].sort((a, b) => a.field.localeCompare(b.field, undefined, {numeric: true, sensitivity: 'base'}));
      [...this.groupDevices.values()].forEach((device_data) => {
        const export_data = {};
        exported.forEach((field_data) => {
          if (field_data.inner) {
            export_data[field_data.field] = device_data[field_data.field_name][field_data.inner];
          }else {
            export_data[field_data.field] = device_data[field_data.field_name];
          }
        });
        allDevices.push(export_data);
      });
    }else {
      this.fields = Object.keys(this.fixture_meta_device_options);
      const selectedfields = ['cabinet_id', 'phase', 'circuit_number', 'watts', 'pole_number', 'pole_height', 'pole_type'];

      exported.push({field: 'Device Id', selected: true, location: 'id'});
      exported.push({field: 'Device Name', selected: true, location: 'name'});
      exported.push({field: 'Version Number', selected: true, location: 'version_nrf52840'});
      exported.push({field: 'Device Latitude', selected: true, location: 'lat'});
      exported.push({field: 'Device Longitude', selected: true, location: 'lng'});
      exported.push({field: 'Device sys_active', selected: false, location: 'sys___active'});
      exported.push({field: 'Device Commissioner', selected: false, location: 'commissioner'});
      exported.push({field: 'Device Commission Date', selected: false, location: 'date'});

      if (this.fields.length) {
        const selected = this.fields.filter((field) => selectedfields.indexOf(field) > -1);
        selected.forEach((field) => exported.push({field, selected: true, location: 'meta.device'}));
        const not_selected = this.fields.filter((field) => selectedfields.indexOf(field) === -1);
        not_selected.forEach((field) => exported.push({field, selected: false, location: 'meta.device'}));
      }

      this.exportFields = [...exported].sort((a, b) => a.field.localeCompare(b.field, undefined, {numeric: true, sensitivity: 'base'}));

      for (const device of this.groupDevices) {
        allDevices.push({
          'Device Id': device[1].id,
          'Device Name': device[1].name,
          ...device[1]['meta.device'],
          'Device Latitude': device[1]['meta.location'].lat,
          'Device Longitude': device[1]['meta.location'].lng,
          'Device Commission Date': device[1]['meta.commission'].date,
          'Device Commissioner': device[1]['meta.commission'].commissioner,
          ...device[1]['meta.commission'],
          'Device sys_active': device[1].sys___active,
          'Version Number': device[1]['version_nrf52840']
        });
      }
      for (const device of allDevices) {
        delete device.commissioned;
        delete device.hubConnection;
        for (const field of this.fields) {
          if (!device.hasOwnProperty(field)) {
            device[field] = '';
          }
        }
      }
    }
    this.allDevicesData = allDevices;
    this.setExportedData();
  }

  setExportedData() {
    const devicesData = JSON.parse(JSON.stringify(this.allDevicesData));
    const selected = this.exportFields.filter((field_data) => field_data.selected).map((field_data) => field_data.field);
    const selected_devicesData = devicesData.map((device_data) => {
      const selected_device_fields = {};
      selected.forEach((selected_field) => {
        selected_device_fields[selected_field] = device_data[selected_field];
    });
      return selected_device_fields;
    });
    this.exportedData = selected_devicesData;
  }

  @Watch('groupDevices')
  async setIsChecked() {
    this.isChecked = {};
    this.checkedDevices = [];

    this.devices.forEach((device) => (this.isChecked[device.id] = this.groupDevices.has(device.id)));

    const tempCheckedDevices = [];
    for (const device_id in this.isChecked) {
      if (this.isChecked[device_id] === true) {
        if (this.devicesList.find((device) => device.id === device_id)) {
          const deviceName = this.devicesList.find((device) => device.id === device_id)['name'];
          tempCheckedDevices.push({
            id: device_id,
            name: deviceName,
            cabinet: this.commissioned_map.get(device_id)['meta.device'].cabinet_id,
            circuit: this.commissioned_map.get(device_id)['meta.device'].circuit_number,
            pole: this.commissioned_map.get(device_id)['meta.device'].pole_number
          });
        }
      }
    }
    this.controlled_devices = tempCheckedDevices.map((device) =>
      this.$store.state.Reports.reportsList.find((dev) => dev.id === device.id)
    );
    if (this.controlled_devices.length) {
      [this.converted, this.percentToDaliTable] =
        await Utils.convertDimmerValues(this.controlled_devices[0].id);
    }
    this.checkedDevices = tempCheckedDevices.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }

  async setPowerLevel(power_percent) {
    this.loadingDialog = true;
    const dali_power = this.converted
      ? this.percentToDaliTable[power_percent]
      : Math.round((power_percent * 254) / 100);
    const slices = Math.ceil(this.controlled_devices.length / 5);
    let startIndex = 0, endIndex = Math.min(this.controlled_devices.length, 5);
    for (let i = 0; i < slices; i++) {
      const current = this.controlled_devices.slice(startIndex, endIndex);
      await Promise.all(
        current.map((device) => {
          try {
            this.updateDevicePower(device.id, dali_power);
          } catch (e) {
            try {
              this.updateDevicePower(device.id, dali_power);
            } catch (e) {}
          }
        })
      );
      startIndex = endIndex;
      endIndex = Math.min(this.controlled_devices.length, endIndex + 5);
    }
    setTimeout(() => {
      this.loadingDialog = false;
    }, 5000);
  }

  updateDevicePower(device_id, power) {
    return API.post(
      '',
      `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${device_id}/streams/power/cmd/`,
      { content: power },
      {},
      'v4'
    );
  }

  setDevicesList(selected_fields) {
    this.filter_fields = cloneDeep(selected_fields);
    const devices = this.getDevicesByFilter();
    this.$emit('selected', devices, true, 'filter');
  }

  sendDeviceCommand() {
    const mqtt_publish_data = {
      message_type: 'cmd',
      format: 'minimal',
      stream_value: 'AT+reload',
      options: {
        retain: false,
        qos: 1
      }
    };

    [...this.groupDevices.values()].forEach((device) => {
      let mqtt_version = 3;
      if (this.mqtt_version_by_class.has(device.class_name)) {
        mqtt_version = this.mqtt_version_by_class.get(device.class_name).mqtt_version;
      }
      this.mqtt.publishToSingleDevice({
        ...mqtt_publish_data,
        version_num: mqtt_version,
        device_id: device.id,
        stream_id: mqtt_version === 3 ? `${device.id}.command` : 'command'
      });
    });
  }

  getFilterList() {
    let filter_list_string = '';
    this.filter_fields.forEach((field, index) => {
      filter_list_string = filter_list_string.concat(
        `${field.name}: ${field.selected.join(', ')}`
      );
      if (index < this.filter_fields.length - 1)
        filter_list_string = filter_list_string.concat(', ');
    });

    return filter_list_string;
  }

  async handleSelect() {
    this.tab = 'choose';
  }

  async saveGroup(devicesMapping) {
    const data = {
      data: {
        ...this.group,
        name: this.groupName,
        description: this.groupDescription,
        devices: [...this.groupDevices.keys()],
        mapping: [{ power: devicesMapping }]
      },
      projectID: this.project.id,
      groupID: this.group.id
    };
    await this.edit(data);
  }

  async createGroup(devicesMapping) {
    const data = {
      projectID: this.project.id,
      name: this.groupName,
      description: this.groupDescription,
      devices: [...this.groupDevices.keys()],
      mapping: [{ power: devicesMapping }]
    };
    await this.create(data);
  }

  updateDevices(d) {
    const device = this.$store.state.Reports.reportsList.find((device) => device.id === d.id);
    this.$emit('selected', [{...device}], false);
    this.allChecked = this.devices.length === this.groupDevices.size;
  }

  selectAll() {
    if (this.allChecked) {
      const currentDevices = this.filter_fields.length
        ? this.getDevicesByFilter()
        : this.devices;
      const selected = currentDevices.filter((v) =>
        v.name.toLowerCase().includes(this.search.toLowerCase())
      );
      this.$emit('selectAll', selected);
    } else this.$emit('selectAll', []);
  }

  clearData() {
    this.filter_fields = [];
    this.search = '';
    this.checkedDevices = [];
    this.$emit('clear');
  }
}
