import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import vuex from '..';
import get from 'lodash/get';

export const columns = [
  'id',
  'project_id',
  'class_name',
  'name',
  'meta.category.category',
  'meta.location',
  'meta.device',
  'meta.commission',
  'sys___active',
  'sys___active__modified',
  'sys___connected',
  'sys___online',
  'power',
  'dmodified_ts'
];

export const tondo_columns = [
  'parent_id', // lora - tondo
  'device.hardwareid',
  'meta.part_number.part_number',
  'device.schedule',
  'smodified_ts',
  'rssi',
  'disconnect_alert',
  'meta.type.type', // asset - tondo
  'power_consumption',
  'meta.schedule_curve',
  'meta.imsi.imsi',
  'low_power_alert',
  'meta.install_location',
  'version_nrf52840'
  // 'monogoto_status',
  // 'dashboard.communication_monitoring',
  // 'meta.stream', // video
];

const cabinet_columns = [
  'created_ts',
  'device.hardwareid',
  'meta.hardware_ids',
  'device.schedule',
  'smodified_ts',
  'rssi',
  'dashboard.connection_display',
  'services.config',
  'meta.devices_schedule',
  'circuits',
  'device_connected_percent',
  'meta.imsi.imsi',
  'device.version'
];

const sensors_columns = [
  'water_mark',
  'water_height_trigger',
  'lsi1',
  'lsi2'
];

const cacheReportsColumns = {};
const ITEMS = 5000;
const mqtt_map_streams = ['power', 'sys___active', 'sys___connected', 'lsi1', 'lsi2', 'water_mark', 'water_height_trigger'];
const cabinets_class_names = `'Power_Meter_HummingBoard', 'Power_Meter_Hummingboard', 'sbc_cabinet', 'sbc_cabinet_extensions', 'Extension_Card', 'sbc_cabinet_pm_135', 'virtual_cabinet'`;
const sensors_class_names = `'NB_BLE_nRF52V2_flood_sensor', 'Sensor', 'Water_Meter', 'rule_board'`;

const setDeviceData = (device, index_i, index_j, default_location) => {
  let i = index_i, j = index_j;

  device.has_default_location = false;
  try {
    device['meta.commission'] = PropUtils.parseProperty('meta.commission', device);
    device['meta.device'] = PropUtils.parseProperty('meta.device', device);
    PropUtils.reorderMetaDevice(device['meta.device']);
    device['meta.location'] = PropUtils.parseProperty('meta.location', device);
    device.has_valid_location = Utils.isValidLocation(device['meta.location']);

    if (device.has_valid_location) {
      device['meta.location'].lat = parseFloat(device['meta.location'].lat);
      device['meta.location'].lng = parseFloat(device['meta.location'].lng);
      device.lat = device['meta.location'].lat;
      device.lng = device['meta.location'].lng;
    }else if (default_location) {
      j = i / 4 / 100000;
      if (i % 4 === 0) {
        device['meta.location'].lat = default_location.lat;
        device['meta.location'].lng = default_location.lng + j;
      } else if (i % 4 === 1) {
        device['meta.location'].lat = default_location.lat + j;
        device['meta.location'].lng = default_location.lng;
      } else if (i % 4 === 2) {
        device['meta.location'].lat = default_location.lat;
        device['meta.location'].lng = default_location.lng - j;
      } else {
        device['meta.location'].lat = default_location.lat - j;
        device['meta.location'].lng = default_location.lng;
      }
      device.lat = device['meta.location'].lat;
      device.lng = device['meta.location'].lng;
      i++;
      device.has_default_location = true;
    }
  } catch (e) {
    console.log('%c' + 'error:' + e + '\ndevice:' + device.id, 'background: #F74242; color: white');
  }
  return [i, j];
};

@Module({ namespaced: true })
export default class Reports extends VuexModule {
  reportsList: any[] = [];
  devicesCount: number = -1;
  cabinetsCount: number = -1;
  pagesCount: number = 0;
  companyId: string = '';
  projectId: string = '';
  allProjectsData: any = {};
  devicesChangedFromMqtt = new Map();

  @Action
  async updateDeviceMode(project_id, device_id, mode) {
    return API.post(
      '',
      `${Types.PROJECTS}/${project_id}/${Types.DEVICES}/${device_id}/streams/sys___active/evt/`,
      { content: mode },
      {},
      'v4'
    );
  }

  @Action
  resetReports() {
    vuex.commit('Reports/clearReports');
  }

  @Mutation
  setDeviceField(data) {
    const device = this.reportsList.find((device) => device.id === data.device_id);
    if (device && device[data.field] !== data.value) {
      device[data.field] = !isNaN(data.value) ? +data.value : data.value;
      if (mqtt_map_streams.includes(data.field)){
        vuex.commit('Reports/setDeviceChangedFromMqtt', device);
      }
    }
  }

  @Mutation
  setDeviceChangedFromMqtt(device) {
    this.devicesChangedFromMqtt.set(device.id, device);
  }

  @Mutation
  removeDeviceChangedFromMqtt(device_id) {
    this.devicesChangedFromMqtt.delete(device_id);
  }

  @Mutation
  clearReports() {
    this.reportsList = [];
  }

  @Mutation
  setDevicesReportsList(data: any) {
    let i = 0, j = 0;
    const meta_device_options = new Map();

    if (data.type === 'cabinet') {
      data.devices.forEach((device) => {
        [i, j] = setDeviceData(device, i, j, data.default_location);
        device['device.version'] = PropUtils.parseProperty('device.version', device);
        if (device['meta.commission'].commissioned){
          const lowercase_fields = PropUtils.getLowercaseFields(device['meta.device']).filter(([field_name, value]) => !field_name.includes('backup'));
          lowercase_fields.forEach(([field_name, value]) => {
            meta_device_options.has(field_name) 
              ? meta_device_options.get(field_name).push(value)
              : meta_device_options.set(field_name, [value]);
          });
        }
      });
      vuex.commit('Props/setCabinetMetaDeviceOptions', meta_device_options);
    }else if (data.type === 'rest') {
      data.devices.forEach((device) => {
        [i, j] = setDeviceData(device, i, j, data.default_location);
        if (Utils.hasTondoClass(device.class_name) && device['meta.commission'].commissioned) {
          const lowercase_fields = PropUtils.getLowercaseFields(device['meta.device']).filter(([field_name, value]) => !field_name.includes('backup'));
          lowercase_fields.forEach(([field_name, value]) => {
            meta_device_options.has(field_name) 
              ? meta_device_options.get(field_name).push(value)
              : meta_device_options.set(field_name, [value]);
          });
        }
      });
      vuex.commit('Props/setFixtureMetaDeviceOptions', meta_device_options);
    }else {
      data.devices.forEach((device) => [i, j] = setDeviceData(device, i, j, data.default_location));
    }

    vuex.commit('Reports/setDevicesReports', data.devices);
  }

  @Mutation
  setDevicesReports(data: any) {
    this.reportsList.push(...data);
  }

  @Mutation
  setDevicesCount(count) {
    this.devicesCount = this.devicesCount === -1 ? count : this.devicesCount + count;
  }

  @Mutation
  setCabinetsCount() {
    this.cabinetsCount = vuex.getters['Reports/commissionedCabinets'].length;
  }

  @Mutation
  clearCabinetsCount() {
    this.cabinetsCount = -1;
  }

  @Mutation
  clearDevicesCount(){
    this.devicesCount = -1;
  }

  @Mutation
  setPagesCount(count) {
    this.pagesCount = count;
  }

  @Mutation
  setCompanyId(id) {
    this.companyId = id;
  }

  @Mutation
  setProjectId(id) {
    this.projectId = id;
  }

  @Mutation
  setAllProjectsData(allProjectsData) {
    this.allProjectsData = allProjectsData;
  }

  @Mutation
  deleteDeviceFromList(deviceId) {
    this.reportsList = this.reportsList.filter((device) => device.id !== deviceId);
    vuex.commit('Reports/setDevicesCount', -1);
  }

  @Mutation
  addDeviceToList(device) {
    this.reportsList.push(device);
    vuex.commit('Reports/setDevicesCount', 1);
  }

  @Mutation
  editDeviceField(field_data) {
    const device_to_update = this.reportsList.find((device) => device.id === field_data.device_id);
    if (device_to_update) {
      device_to_update[field_data.name] = field_data.value;
    }
  }

  @Action
  async loadReports(data) {
    const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    vuex.commit('Props/defineProjectDefaultLocation', data.id);
    vuex.commit('Global/setProjectSunTime');
    vuex.commit('Reports/setCompanyId', data.company || (user && user.company));
    vuex.commit('Reports/setProjectId', data.id);

    if (data.id === 'allProjects') {
      vuex.commit('Reports/setAllProjectsData', data);
    }

    try {
      if (data.id === 'allProjects') {
        await vuex.dispatch('Reports/loadReportColumnsAllProjects', data);
        await vuex.dispatch('Reports/getPagesCountAllProjects', data);
        await vuex.dispatch('Reports/getAllDevicesAllProjects', data);
      } else {
        await vuex.dispatch('Reports/loadReportColumns');
        await vuex.dispatch('Reports/getPagesCount');
        await vuex.dispatch('Reports/getAllDevices');
      }
    } catch (e) {
      vuex.commit('Errors/setError', get(e, 'response.data.error.message', e));
      vuex.commit('Errors/setShowError', true);
      console.error(e);
    }
  }

  @Action
  async loadReportColumns() {
    const company_id = (this.state as any).companyId;
    const project_id = (this.state as any).projectId;
    if (!cacheReportsColumns.hasOwnProperty(company_id)) {
      try {
        const response = await API.get(
          Types.COMPANIES,
          `/${company_id}/projects/${project_id}/reporting_get_columns`
        );
        cacheReportsColumns[company_id] = response.results;
      } catch (e) {
        throw e;
      }
    }
  }

  @Action
  async getPagesCount() {
    const request_data = {
      type: 'count',
      columns: ['count(*)'],
      page_number: 0
    };
    try {
      await vuex.dispatch('Reports/loadReportsPage', request_data);
      const count = (this.state as any).devicesCount;
      if (count) {
        let pages_count = 0;
        pages_count = count > ITEMS ? Math.floor(count / ITEMS) + 1 : 1;
        vuex.commit('Reports/setPagesCount', pages_count);
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  async getAllDevices() {
    const company_id = (this.state as any).companyId;

    try {
      vuex.dispatch('Reports/fetchSensors', company_id);
      vuex.dispatch('Reports/fetchCabinets', company_id);
      vuex.dispatch('Reports/fetchRestDevices', company_id);
    } catch (e) {
      throw e;
    }
  }

  @Action
  fetchSensors(company_id){
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(sensors_columns));

    vuex.dispatch('Reports/loadReportsPage', {
      type: 'sensor',
      columns: final_columns,
      page_number: 0,
      default_location: (vuex.state as any).Props.project_default_location,
      order_by: 'id',
      where: [`class_name IN [${sensors_class_names}]`]
    });
  }

  @Action
  fetchCabinets(company_id){
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(cabinet_columns));

    vuex.dispatch('Reports/loadReportsPage', {
      type: 'cabinet',
      columns: final_columns,
      page_number: 0,
      default_location: (vuex.state as any).Props.project_default_location,
      order_by: 'id',
      where: [`class_name IN [${cabinets_class_names}]`]
    }).then(() => vuex.commit('Reports/setCabinetsCount'));
  }

  @Action
  fetchRestDevices(company_id){
    const pages = (this.state as any).pagesCount;
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(tondo_columns));

    Promise.all(
      [...Array(pages).keys()].map(async (page_number) => {
        vuex.dispatch('Reports/loadReportsPage', {
          type: 'rest',
          columns: final_columns,
          page_number,
          default_location: (vuex.state as any).Props.project_default_location,
          order_by: 'id',
          where: [`class_name NOT IN [${cabinets_class_names}, ${sensors_class_names}]`]
        });
      })
    );
  }

  @Action
  async loadReportsPage(request_data) {
    const project_id = (this.state as any).projectId;
    const company_id = (this.state as any).companyId;
    if (project_id === 'allProjects') return;
    
    const body = {
      columns: request_data.columns,
      page: request_data.page_number,
      items: ITEMS
    };

    if (request_data.order_by) body['order_by'] = request_data.order_by;
    if (request_data.where) body['where'] = request_data.where;
    try {
      const result = await API.post(
        Types.COMPANIES,
        company_id + '/projects/' + project_id + '/reporting_query/',
        body,
        {}
      );
      if (result && result.results && result.results.length) {
        if (request_data.type === 'count') {
          vuex.commit('Reports/setDevicesCount', result.results[0].count);
        } else {
          const devices = result.results;
          vuex.commit('Reports/setDevicesReportsList', {
            type: request_data.type,
            devices,
            default_location: request_data.default_location
          });
        }
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  async loadReportColumnsAllProjects(allProjectsData) {
    if (
      !allProjectsData ||
      allProjectsData === undefined ||
      !allProjectsData.projects
    )
      return;
    allProjectsData.projects.forEach(async (item) => {
      const company_id = item.company;
      const project_id = item.id;
      if (!cacheReportsColumns.hasOwnProperty(company_id)) {
        try {
          const response = await API.get(
            Types.COMPANIES,
            `/${company_id}/projects/${project_id}/reporting_get_columns`
          );
          cacheReportsColumns[company_id] = response.results;
        } catch (e) {
          throw e;
        }
      }
    });
  }
  
  @Action
  async getPagesCountAllProjects(allProjectsData) {
    const request_data = {
      type: 'count',
      columns: ['count(*)'],
      page_number: 0
    };
    try {
      await vuex.dispatch('Reports/loadReportsPageAllProjects', {
        request_data,
        allProjectsData
      });
      const count = (this.state as any).devicesCount;
      if (count) {
        let pages_count = 0;
        pages_count = count > ITEMS ? Math.floor(count / ITEMS) + 1 : 1;
        vuex.commit('Reports/setPagesCount', pages_count);
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  async getAllDevicesAllProjects(data) {
    const company_id = data.companiesAllProjects[0];

    try {
      vuex.dispatch('Reports/fetchSensorsAllProjects', {company_id, data});
      vuex.dispatch('Reports/fetchCabinetsAllProjects', {company_id, data});
      vuex.dispatch('Reports/fetchRestDevicesAllProjects', {company_id, data});
     
    } catch (e) {
      throw e;
    }
  }

  @Action
  fetchCabinetsAllProjects({company_id, data}){
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(cabinet_columns));

    const request_data = {
      type: 'cabinet',
      columns: final_columns,
      page_number: 0,
      default_location: (vuex.state as any).Props.project_default_location,
      order_by: 'id',
      where: [`class_name IN [${cabinets_class_names}]`]
    };
    vuex.dispatch('Reports/loadReportsPageAllProjects', {
      request_data,
      allProjectsData: data
    }).then(() => vuex.commit('Reports/setCabinetsCount'));
  }

  @Action
  fetchSensorsAllProjects({company_id, data}){
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(sensors_columns));

    const request_data = {
      type: 'sensor',
      columns: final_columns,
      page_number: 0,
      default_location: (vuex.state as any).Props.project_default_location,
      order_by: 'id',
      where: [`class_name IN [${sensors_class_names}]`]
    };
    vuex.dispatch('Reports/loadReportsPageAllProjects', {
      request_data,
      allProjectsData: data
    });
  }

  @Action
  fetchRestDevicesAllProjects({company_id, data}){
    const pages = (this.state as any).pagesCount;
    const final_columns = Utils.getFilteredColumns(cacheReportsColumns[company_id], columns.concat(tondo_columns));

    Promise.all(
      [...Array(pages).keys()].map(async (page_number) => {
        const request_data = {
          type: 'rest',
          columns: final_columns,
          page_number,
          default_location: (vuex.state as any).Props.project_default_location,
          order_by: 'id',
          where: [`class_name NOT IN [${cabinets_class_names}, ${sensors_class_names}]`]
        };
        vuex.dispatch('Reports/loadReportsPageAllProjects', {
          request_data,
          allProjectsData: data
        });
      })
    );
  }

  @Action
  async loadReportsPageAllProjects({ request_data, allProjectsData }) {
    if (!allProjectsData || allProjectsData === undefined || !allProjectsData.projects) return;
    for await (const item of allProjectsData.projects) {
      const project_id = item.id;
      const company_id = item.company;

      const body = {
        columns: request_data.columns,
        page: request_data.page_number,
        items: ITEMS
      };

      if (request_data.order_by) body['order_by'] = request_data.order_by;
      if (request_data.where) body['where'] = request_data.where;
      try {
        const result = await API.post(
          Types.COMPANIES,
          company_id + '/projects/' + project_id + '/reporting_query/',
          body,
          {}
        );
        if (result && result.results && result.results.length) {
          if (body.page === 0 && result.results[0].count) {
            vuex.commit('Reports/setDevicesCount', result.results[0].count);
          } else {
            const devices = result.results;
            vuex.commit('Reports/setDevicesReportsList', {
              type: request_data.type,
              devices,
              default_location: request_data.default_location
            });
          }
        }
      } catch (e) {
        console.log(`error: ${JSON.stringify(e)}`);
        throw e;
      }
    }
  }

  get devicesList_map() {
    const devices_map = new Map();
    this.reportsList.forEach((device) => devices_map.set(device.id, device));
    return devices_map;
  }

  get cabinets() {
    return this.reportsList.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
  }

  get fixtures() {
    return this.reportsList.filter((device) => Utils.hasTondoClass(device.class_name));
  }

  get cabinets_fixtures() {
    const devices_by_cabinet = new Map();
    vuex.getters['Reports/commissionedCabinets'].forEach((cabinet) => {
      const cabinet_id = cabinet['meta.device'].cabinet_id;
      const related_devices = vuex.getters['Reports/commissionedFixtures'].filter((device) => device['meta.device'].cabinet_id === cabinet_id);
      devices_by_cabinet.set(cabinet.id, related_devices);
    });

    return devices_by_cabinet;
  }

  get ruleBoards() {
    return this.reportsList.filter((device) => Utils.hasRuleBoardClass(device.class_name));
  }

  get commissionedDevices() {
    return this.reportsList.filter((device) => Utils.checkCommissioned(device['meta.commission']));
  }

  get commissioned_map() {
    const devices_map = new Map();
    vuex.getters['Reports/commissionedDevices'].forEach((device) => {
      devices_map.set(device.id, device);
    });
    return devices_map;
  }

  get commissionedWithLocation() {
    return this.reportsList.filter(
      (device) => 
        Utils.checkCommissioned(device['meta.commission']) && 
        device.has_valid_location
    );
  }

  get commissionedFixtures() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasTondoClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedFixtures_map() {
    const devices_map = new Map();
    vuex.getters['Reports/commissionedFixtures'].forEach((device) => {
      devices_map.set(device.id, device);
    });
    return devices_map;
  }

  get commissionedCabinets() {
    return vuex.getters['Reports/cabinets'].filter(
      (device) => Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedCabinets_map() {
    const devices_map = new Map();
    vuex.getters['Reports/commissionedCabinets'].forEach((device) => {
      devices_map.set(device.id, device);
    });
    return devices_map;
  }

  get commissionedMotionSensor() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasMotionSensorClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedFloodSensor() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasFloodSensorClass(device.class_name, device['meta.category.category']) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedBarrier(){
    return this.reportsList.filter(
      (device) =>
        Utils.hasBarrierClass(device.class_name, device['meta.category.category']) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedWaterMeter() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasWaterMeterClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedWeatherStation() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasWeatherStationClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedRadar() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasRadarClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get commissionedCamera() {
    return this.reportsList.filter(
      (device) =>
        Utils.hasCameraClass(device.class_name) &&
        Utils.checkCommissioned(device['meta.commission'])
    );
  }

  get motion_sensor_and_meta_device_map() {
    const devices_map = new Map();
    vuex.getters['Reports/commissionedMotionSensor'].forEach((device) => {
      const metaDevice = device['meta.device'];
      devices_map.set(device.id, [device, metaDevice]);
    });
    return devices_map;
  }

  get assetTypeList() {
    const assetTypeList = new Set();

    vuex.getters['Reports/commissionedWithLocation'].forEach((device) => {
      if (device['meta.type.type']) assetTypeList.add(device['meta.type.type']);
    });

    return [...assetTypeList];
  }

  get devicesTypeList() {
    const devicesTypeList = new Set();

    vuex.getters['Reports/commissionedWithLocation'].forEach((device) => {
      if (device['meta.category.category']) devicesTypeList.add(device['meta.category.category']);
    });

    return [...devicesTypeList];
  }

  get deviceParentList() {
    const parentList = this.reportsList.reduce((r, a) => {
      r[a.parent_id] = r[a.parent_id] || [];
      r[a.parent_id].push(a);
      return r;
    }, Object.create(null));
    return parentList;
  }
}
