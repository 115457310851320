<template>
  <div 
    id="map-view"
    class="elevation-1 map-container"
    ref="mapWrap"
    :class="mapHeight"
  >
    <GmapMap
      ref="map"
      :center="center"
      :options="mapOptions"
      :style="mapStyles"
      style="width: 100%"
    >
      <gmap-info-window
        :options="infoOptionsCurrent"
        :position="infoPositionCurrent"
        :opened="infoOpenedCurrent"
        @closeclick="closeWindow"
      >
        <div v-html="infoContentCurrent"></div>
      </gmap-info-window>
    </GmapMap>

    <div class="center-container" v-if="loadingDevices">
      <GifLoading />
    </div>
    <div
      :class="displayFullMapBtn ? 'bottom-right-selector' : 'lower-bottom-right-selector'"
      v-if="($route.path === '/' && $store.state.User.project.company !== 'allProjects' && !current_device && !searchActivated && advancedMap)
        || ($route.path.includes('cabinet-details') && !current_device)"
    >
      <v-tooltip top>
        <v-btn
          :color="deviceSelectorChecked ? 'grey lighten-1' : 'white'"
          slot="activator"
          style="box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important; border-radius: 0px !important; height: 40px; width: 40px"
          @click="updateDeviceSelectorData"
          icon
        >
          <v-icon v-if="deviceSelectorChecked" color="red">mdi-close-box</v-icon>
          <v-icon v-else>mdi-vector-rectangle</v-icon>
        </v-btn>
        <span>{{ deviceSelectorChecked ? $t('Exit device manager') : $t('Choose Devices') }}</span>
      </v-tooltip>
    </div>
    <div 
      v-if="deviceSelectorChecked && !$route.path.includes('/groups/')"
      :class="displayFullMapBtn ? 'bottom-right-selector-div' : 'lower-bottom-right-selector-div'"
    >
      <v-tooltip v-if="polygonMarkers.length > 0" top style="z-index: 999">
        <v-btn class="btn-custom-toggle mr-1" color="grey" slot="activator" icon @click="clearPolySelection">
          <v-icon>mdi-select-off</v-icon>
        </v-btn>
        <span>{{ $t('Clear Selection') }}</span>
      </v-tooltip>
      <template v-if="polygonMarkers.length > 2">
        <v-tooltip top style="z-index: 999">
          <v-btn class="btn-custom-toggle mr-1" color="green" slot="activator" icon @click="addSelectedDevices(true)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <span>{{ $t('Add Devices') }}</span>
        </v-tooltip>
        <v-tooltip top style="z-index: 999">
          <v-btn class="btn-custom-toggle mr-1" color="red" slot="activator" icon @click="addSelectedDevices(false)">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <span>{{ $t('Remove Devices') }}</span>
        </v-tooltip>
      </template>
    </div>
    <div class="bottom-right-container" v-if="displayFullMapBtn">
      <v-tooltip top>
        <div slot="activator" class="btn-resizer mobile-hide" @click="setIsFullMap(!fullMap)">
          <i></i>
        </div>
        <span>{{ $t('Maximize Map') }}</span>
      </v-tooltip>
    </div>
    <div row class="settings-btn-group">
      <div column style="position: relative">
        <template v-if="location && location.indexOf('/reports/monitoring_report') === -1">
          <v-tooltip v-if="$route.path.includes('cabinet-details')" top>
            <v-btn
              slot="activator"
              icon
              class="btn-custom-toggle background-white mr-1"
              :class="{ active: showCircuitNumbers }"
              @click="toggleCircuitNumbers"
            >
              <v-icon>mdi-numeric</v-icon>
            </v-btn>
            <span>{{ showCircuitNumbers ? $t('Hide circuit numbers') : $t('Show circuit numbers') }}</span>
          </v-tooltip>
          <v-tooltip v-if="!group && !dragOnly" top>
            <v-btn
              slot="activator"
              icon
              class="btn-custom-toggle background-white mr-1"
              :class="{ active: isSatelliteMap }"
              @click="toggleSatelliteMap"
            >
              <v-icon>mdi-satellite</v-icon>
            </v-btn>
            <span>{{ $t('Satellite Map') }}</span>
          </v-tooltip>
          <template v-if="$route.path.includes('/groups/') && showSelectionTools">
            <template v-if="deviceSelectorChecked">
              <v-tooltip top style="z-index: 999" v-if="polygonMarkers.length > 0">
                <v-btn class="btn-custom-toggle mr-1" color="grey" slot="activator" icon @click="clearPolySelection">
                  <v-icon>mdi-select-off</v-icon>
                </v-btn>
                <span>{{ $t('Clear Selection') }}</span>
              </v-tooltip>
              <template v-if="polygonMarkers.length > 2">
                <v-tooltip top style="z-index: 999">
                  <v-btn class="btn-custom-toggle mr-1" color="green" slot="activator" icon @click="addDevices(true)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <span>{{ $t('Add Devices') }}</span>
                </v-tooltip>
                <v-tooltip top style="z-index: 999">
                  <v-btn class="btn-custom-toggle mr-1" color="red" slot="activator" icon @click="addDevices(false)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <span>{{ $t('Remove Devices') }}</span>
                </v-tooltip>
              </template>
            </template>

            <v-tooltip top style="z-index: 999">
              <v-btn
                class="btn-custom-toggle mr-1"
                :color="deviceSelectorChecked ? 'grey lighten-1' : 'white'"
                slot="activator"
                icon
                @click="setIsSelectorChecked"
              >
                <v-icon>mdi-select-drag</v-icon>
              </v-btn>
              <span>{{ $t('Multiple Selection') }}</span>
            </v-tooltip>
          </template>
          <ExportData
            v-if="!dragOnly"
            class="mr-1"
            :data="current_device ? [...current_device] : devices"
            color="black"
            :text="false"
            :sourceType="current_device ? 'device' : 'view'"
            :sourceTitle="current_device ? current_device.name : infoFromMapview"
            :isGroups="group"
          />
          <!-- <v-tooltip top>
            <v-btn
              slot="activator"
              icon
              class="btn-custom-toggle background-white"
              :class="{active: showHeatMap}"
              @click="toggleHeatMap"
            >
              <v-icon>rss_feed</v-icon>
            </v-btn>
            <span>{{ $t('Toggle RSSI') }}</span>
          </v-tooltip> -->
          <template v-if="!dragOnly">
            <v-tooltip top style="z-index: 999">
              <v-btn slot="activator" icon class="btn-custom-toggle background-white mr-1" @click="toggleSearch">
                <v-icon>search</v-icon>
              </v-btn>
              <span>{{ $t('Address search') }}</span>
            </v-tooltip>
            <v-text-field
              v-model="search_value"
              id="search-input"
              v-show="openSearch"
              append-icon="mdi-close"
              class="search-input"
              solo
              @click:append="toggleSearch"
              :placeholder="$t('Search address')"
              type="text"
            ></v-text-field>
          </template>
          <v-tooltip v-if="!group && !dragOnly" top>
            <v-btn class="background-white mr-1" slot="activator" icon @click="toggleMapSettings" :disabled="readonly_user">
              <v-icon>settings</v-icon>
            </v-btn>
            <span>{{ $t('Map Style') }}</span>
          </v-tooltip>
        </template>
        <v-tooltip v-if="(!group && !simpleMap || simpleMap && advancedMap) && !current_device" top>
          <v-btn
            slot="activator"
            class="mobile-hide btn-custom-toggle background-white mr-1"
            :class="{ active: deviceDrag ? true : false }"
            icon
            @click="toggleDragMarkers"
            :disabled="readonly_user"
          >
            <v-icon>touch_app</v-icon>
          </v-btn>
          <span>{{ $t('Reposition device') }}</span>
        </v-tooltip>
        <template v-if="location && location.indexOf('/reports/monitoring_report') === -1">
          <v-tooltip v-if="!group && !dragOnly && !simpleMap || simpleMap && advancedMap" top>
            <v-btn
              slot="activator"
              icon
              :class="showAdvancedLocations ? 'btn-custom-toggle background-white color-green mr-1' : 'btn-custom-toggle background-white mr-1'"
              @click="setAdvancedLocationsIsOpen"
            >
              <v-icon>mdi-navigation</v-icon>
            </v-btn>
            <span>{{ $t('Location') }}</span>
          </v-tooltip>
          <v-tooltip v-if="simpleMap" top>
            <v-btn
              slot="activator"
              class="mobile-hide btn-custom-toggle background-white mr-1"
              :class="{ active: advancedMap }"
              icon
              @click="showAdvancedMap"
            >
              <v-icon>map</v-icon>
            </v-btn>
            <span v-if="advancedMap">{{ $t('Basic Map') }}</span>
            <span v-else>{{ $t('Advanced Map') }}</span>
          </v-tooltip>
        </template>
      </div>
    </div>
    <div>
      <div v-if="showAdvancedLocations && map" class="top-center-container" style="position: absolute">
        <div>
          <v-btn round :color="advancedLocations['markers'] ? '#ffc71e' : 'white'" @click="linkLayerToMap('markers')">
            <span class="font-weight-bold subheading" :style="advancedLocations['markers'] ? 'color: white' : 'color: #ffc71e'">{{ $t('MAP') }}</span>
          </v-btn>
          <v-btn round :color="advancedLocations['gps'] ? 'green' : 'white'" @click="linkLayerToMap('gps')">
            <span class="font-weight-bold subheading" :style="advancedLocations['gps'] ? 'color: white' : 'color: green'">{{ $t('GPS') }}</span>
          </v-btn>
          <v-btn round :color="advancedLocations['gis'] ? 'red' : 'white'" @click="linkLayerToMap('gis')">
            <span class="font-weight-bold subheading" :style="advancedLocations['gis'] ? 'color: white' : 'color: red'">{{ $t('GIS') }}</span>
          </v-btn>
          <v-btn round :color="advancedLocations['app'] ? 'purple' : 'white'" @click="linkLayerToMap('app')">
            <span class="font-weight-bold subheading" :style="advancedLocations['app'] ? 'color: white' : 'color: purple'">{{ $t('APP') }}</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="showLegend && !dragOnly && $store.getters['Reports/commissionedDevices'].length" class="top-left-container">
      <div style="position: relative">
        <v-layout id="map-legend">
          <Legend :map_devices="markers" :advancedMap="advancedMap" :simpleMap="simpleMap"/>
        </v-layout>
      </div>
    </div>
    <div class="map-settings" v-if="showSettings">
      <span>{{ $t('Map Style') }}</span>
      <v-select
        :items="styleList"
        item-text="name"
        item-value="value"
        @change="setMapStyles"
        v-model="mapMode"
        attach
        menu-props="bottom, offsetY"
      ></v-select>
    </div>
    <ConfirmDialog
      v-if="confirmDragDialog"
      color="pink"
      @close="revertDrag"
      @action="updateDevicePosition"
      :action="$t('Change')"
      :persistent="true"
      :text="`${$t('Are you sure you want to change device position')}?`"
    />
    <PropEditor ref="propEditor" />
  </div>
</template>

<script>
import Vue from 'vue';
import * as deviceFactory from './deviceFactory';
import config from './map.config';
import mapStyles from './mapStyles';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import API, { Types } from '@/modules/API';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import ExportData from '@/components/ExportData.vue';
import moment from 'moment';
import { gmapApi as googleMapsApi } from 'vue2-google-maps';
import * as VueGoogleMaps from 'vue2-google-maps';
import PropEditor from '@/components/PropEditor.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import GifLoading from '@/components/GifLoading.vue';
import Legend from '@/pages/mapView/Legend.vue';
import MQTT from '@/modules/MQTT';
import { FloodSensor } from '@/modules/Types';

const mapMaxZoom = 23;
const mapMinZoom = 2;

const defaultStyleSheet = [
  { value: 'defaultMode', name: 'Default' },
  { value: 'silverMode', name: 'Silver' },
  { value: 'nightMode', name: 'Night mode' }
];

export default {
  name: 'GoogleMap',
  components: {
    Legend,
    ExportData,
    PropEditor,
    ConfirmDialog,
    GifLoading
  },
  props: {
    loadingDevices: Boolean,
    current_device: Object,
    is_device_info_open: Boolean,
    devices: Array,
    filter: Object,
    lang: String,
    infoFromMapview: String,
    showSelectionTools: Boolean,
    group: Boolean,
    groupDevices: Map,
    dragOnly: Boolean,
    unassigned: Boolean || null,
    searchClicked: Boolean,
    tab: String,
    setSearchClicked: Function,
    clearDeviceData: Function,
    fullMap: Boolean,
    setIsFullMap: Function,
    advancedMap: Boolean
  },
  data() {
    return {
      mqtt: MQTT.instance,
      simpleMap: false,
      cabinet_circuits: new Map(),
      cabinet_connected_percent: new Map(),
      confirmDragDialog: false,
      map_layers: new Map(),
      isDefaultCabinet: false,
      dragPrevLocation: {lat: null, lng: null},
      dragCurrentLocation: {lat: null, lng: null},
      dragMarker: null,
      dragStarted: false,
      dragFeature: null,
      draggableFeatures: new Map(),
      // cabinets: new Map(),
      // cabinet_ids: [],
      // cabinets_devices: new Map(),
      // fullviewPage: false,
      markers_map: new Map(),
      map: null,
      cabinetPage: false,
      showAdvancedLocations: false,
      tommorow: null,
      yesterday: null,
      today_00: null,
      today_23: null,
      search_value: '',
      showLegend: true,
      center: { lat: 32.08816963237704, lng: 34.79698850793454 },
      markers: [],
      mapStyles: {},
      device: null,
      boundsOptions: {
        padding: 65,
        screenSpeed: 8
      },
      infoPositionCurrent: null,
      infoOpenedCurrent: null,
      infoContentCurrent: null,
      infoOptionsCurrent: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        },
        zIndex: -10000
      },
      hasCustomMapStyles: false,
      styleList: defaultStyleSheet,
      customMapStyles: {},
      mapMode: 'defaultMode',
      wrapStyles: { 'border-radius': '20px', 'outline': 'none !important' },
      openSearch: false,
      showSettings: false,
      apiKey: config.TOKEN_GOOGLE,
      mapOptions: {
        disableDefaultUI: true,
        scaleControl: true,
        zoomControl: true,
        fullscreenControl: true,
        styles: mapStyles.defaultMode,
        maxZoom: mapMaxZoom,
        minZoom: mapMinZoom
      },
      showCircuitNumbers: false,
      isSatelliteMap: false,
      showHeatMap: false,
      redHeatMap: null,
      redHeatMapPoints: [],
      yellowHeatMap: null,
      yellowHeatMapPoints: [],
      greenHeatMap: null,
      greenHeatMapPoints: [],
      gradientGreen: [
        'rgba(139,255,82,0)',
        'rgba(139,255,22,1)',
        'rgba(139,255,22,1)',
        'rgba(139,255,22,1)',
        'rgba(139,255,22,1)'
      ],
      gradientYellow: [
        'rgba(139,255,82,0)',
        'rgba(239,250,1, 1)',
        'rgba(239,250,1, 1)',
        'rgba(239,250,1, 1)',
        'rgba(239,250,1, 1)'
      ],
      gradientRed: [
        'rgba(209, 0, 0, 0)',
        'rgba(229, 0, 0, 1)',
        'rgba(229, 0, 0, 1)',
        'rgba(229, 0, 0, 1)',
        'rgba(229, 0, 0, 1)'
      ],
      location: null,
      projectName: null,
      advancedLocations: {markers: false, gps: false, gis: false, app: false},
      polygonLocations: [],
      polygonMarkers: [],
      polygon: null,
      polyline: null,
      polyline1: null,
      polyline2: null,
      polygonDevices: [],
      mapCurrentBounds: { hiLat: 0, loLat: 0, hiLng: 0, loLng: 0 }, // highLat lowLat highLng lowLng
      searchActivated: false,
      map_layers_listeners: new Map(),
      deviceSelectorChecked: false
    };
  },
  computed: {
    google: googleMapsApi,
    readonly_user() {
      return this.$store.state.Global.readonly_user;
    },
    day_of_week(){
      return this.$store.state.Global.day_of_week;
    },
    projectDefaultLocation(){
      return this.$store.state.Props.project_default_location;
    },
    displayFullMapBtn() {
      return this.$route.path === '/' && !this.current_device && !this.searchClicked && !this.group;
    },
    isMobile() {
      return this.$store.state.Global.isMobile;
    },
    user_default_map(){
      return this.$store.state.User.default_map;
    },
    filteredDevicesRoute(){
      return this.$route.path.includes('/filter/create') 
        || this.$route.path.includes('custom') 
        || this.$route.path.includes('cabinet-details')
        || this.$route.path.includes('monitoring_report')
        || this.$route.params.cid
        || this.tab && this.tab.includes('multiple-search');
    },
    deviceDrag() {
      return this.$store.state.MapSettings.deviceDrag;
    },
    mapHeight(){
      return this.$route.path.includes('monitoring_report') 
        ? 'map-height-install-report' 
        : this.$route.path.includes('cabinet-details')
          ? 'map-height-cabinet-page'
          : this.fullMap
            ? 'map-height-full'
            : this.$route.path.includes('/groups')
              ? 'map-height-group'
              : this.current_device && this.isMobile
                ? 'map-height-device-and-mobile'
                : 'map-height-project-info';
    },
    projectProperties() {
      return this.$store.state.Props.list;
    },
    mapReady() {
      return this.map !== null;
    },
    searchClickedChange() {
      return this.searchClicked;
    }
    // heatPoints() {
    //   if (this.markers.length < 1) return [];

    //   const heatmap_points = [];
    //   this.markers.forEach((marker) => {
    //     const heat_point = {lat: marker['meta.location'].lat, lng: marker['meta.location'].lng, weight: null};
    //     heat_point.weight = marker.rssi ? marker.rssi : marker.rssi === 0 ? 0 : null;
    //     heatmap_points.push(heat_point);
    //   });
    //   return heatmap_points;
    // }
  },
  beforeMount() {
    this.location = this.$route.path;
    this.projectName = this.$store.state.User.project.name;
    this.simpleMap = this.$route.path === '/';
    this.$store.commit('MapSettings/setDeviceRepositioning', false);
  },
  beforeDestroy(){
    this.removeMapLayersListeners();
    this.map = null;
    if (this.mqtt && !this.$route.path.includes('cabinet-details')) {
      this.mqtt.clearListeners();
    }
  },
  mounted() {
    this.mqtt.listenToProjectEvents();

    Vue.use(VueGoogleMaps, {
      load: {
        key: config.TOKEN_GOOGLE,
        language: this.lang === 'he' ? 'iw' : 'en',
        libraries: ['places', 'visualization', 'geometry']
      }
    });
    this.$root.$refs.GoogleMap = this;
    this.cabinetPage = this.$route.path.includes('cabinet-details');
    // this.fullviewPage = this.$route.path === '/';
    this.setDatesInfo();
    this.initSearch();
    this.getProjectMapStyles();
    this.setMapResize();
    this.setMapBounds([]);
  },
  methods: {
    setMapLayers(){
      this.setDataMapLayer('markers');
      this.setDataMapLayer('gps');
      this.setDataMapLayer('gis');
      this.setDataMapLayer('app');
    },
    setDatesInfo() {
      this.yesterday = moment(+localStorage.getItem('now')).subtract(1, 'days').valueOf();
      this.tommorow = moment(+localStorage.getItem('now')).add(1, 'days').valueOf();
      this.today_00 = moment(+localStorage.getItem('now')).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
      this.today_23 = moment(+localStorage.getItem('now')).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).valueOf();
    },
    showHeatMaps(value) {
      this.redHeatMap.setMap(value);
      this.yellowHeatMap.setMap(value);
      this.greenHeatMap.setMap(value);
    },
    setAdvancedLocationsIsOpen() {
      if (this.showSettings) this.showSettings = false;
      this.showAdvancedLocations = !this.showAdvancedLocations;
      if (!this.showAdvancedLocations){
        this.clearPolylines();
        this.clearAdvancedLocations();
      }
    },
    clearAdvancedLocations(){
      if (!this.advancedLocations.markers) {
        Vue.set(this.advancedLocations, 'markers', true);
        this.map_layers.get('markers').setMap(this.map);
      }
      if (this.advancedLocations.gps){
        Vue.set(this.advancedLocations, 'gps', false);
        this.map_layers.get('gps').setMap(null);
      }
      if (this.advancedLocations.gis){
        Vue.set(this.advancedLocations, 'gis', false);
        this.map_layers.get('gis').setMap(null);
      }
      if (this.advancedLocations.app){
        Vue.set(this.advancedLocations, 'app', false);
        this.map_layers.get('app').setMap(null);
      }
    },
    // generateHeatmapLayers(){
    //   this.$nextTick(() => {
    //     this.$refs.map.$mapPromise.then(() => {
    //       const self = this;
    //       try {
    //         this.heatPoints.forEach((coord) => {
    //           if (coord.weight === null) return null;
    //           if (coord.weight < -100) {
    //             this.redHeatMapPoints.push(new google.maps.LatLng(coord.lat, coord.lng));
    //           } else if (coord.weight < -80) {
    //             this.yellowHeatMapPoints.push(new google.maps.LatLng(coord.lat, coord.lng));
    //           } else {
    //             this.greenHeatMapPoints.push(new google.maps.LatLng(coord.lat, coord.lng));
    //           }
    //         });
    //         this.redHeatMap = this.createHeatMapLayer(this.redHeatMapPoints, this.gradientRed, self);
    //         this.yellowHeatMap = this.createHeatMapLayer(this.yellowHeatMapPoints, this.gradientYellow, self);
    //         this.greenHeatMap = this.createHeatMapLayer(this.greenHeatMapPoints, this.gradientGreen, self);
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     });
    //   });
    // },
    toggleHeatMap() {
      this.showHeatMap = !this.showHeatMap;
      this.showHeatMap ? this.showHeatMaps(this.map) : this.showHeatMap(null);
    },
    createHeatMapLayer(data, gradient, self){
      return new google.maps.visualization.HeatmapLayer({
        data,
        map: self.$refs.map.$mapObject,
        radius: 30,
        gradient,
        maxIntensity: 3
      });
    },
    toggleCircuitNumbers() {
      this.showCircuitNumbers = !this.showCircuitNumbers;
      this.markers.forEach((marker) => this.updateFeatureIcon(marker));
    },
    toggleSatelliteMap() {
      if (this.showSettings) this.showSettings = false;
      this.isSatelliteMap = !this.isSatelliteMap;
      if (this.isSatelliteMap) {
        this.$refs.map.$mapObject.setMapTypeId('hybrid');
      } else {
        this.$refs.map.$mapObject.setMapTypeId('roadmap');
      }
    },
    refreshMap() {
      this.$refs.map.$mapObject.setMapTypeId('hybrid');
    },
    setMapResize() {
      const observer = new MutationObserver((m) => {
        this.setMapStyle('height', m[0].target.clientHeight + 'px');
      });
      const child = document.getElementsByClassName('map-container')[0];
      observer.observe(child, { attributes: true });
    },
    smoothZoom(level, cnt) {
      if (cnt >= level) {
        return;
      } else {
        const z = this.map.addListener('zoom_changed', (e) => {
          google.maps.event.removeListener(z);
          this.smoothZoom(level, cnt + 1);
        });
        setTimeout(() => {
          this.map.setZoom(cnt);
        }, 80);
      }
    },
    updateDeviceSelectorData() {
      if (this.showAdvancedLocations) this.setAdvancedLocationsIsOpen();
      if (this.deviceSelectorChecked) {
        this.clearDeviceSelector();
        return;
      }
      this.deviceSelectorChecked = true;
      this.$emit('chooseDevicesSelected', true);
    },
    clearDeviceSelector(){
      this.deviceSelectorChecked = false;
      this.clearPolySelection();
      if (!this.$route.path.includes('/groups/')){
        this.$emit('chooseDevicesSelected', false);
        this.$emit('selectedChanged', this.groupDevices && this.groupDevices.size ? [...this.groupDevices.values()] : []);
      }
    },
    setIsSelectorChecked(){
      if (this.deviceSelectorChecked) {
        this.deviceSelectorChecked = false;
        this.clearPolySelection();
        return;
      }
      this.deviceSelectorChecked = true;
    },
    getMarkerOptions(marker, layer) {
      let icon, metaDevice, final_marker = null;
      try {
        if (marker['meta.device']){
          metaDevice = marker['meta.device'];
          final_marker = marker;
        }else {
          final_marker = this.$store.getters['Reports/commissioned_map'].get(marker.id);
          metaDevice = final_marker['meta.device'];
        }
        icon = this.calculateMarkerIcon(final_marker, metaDevice, layer);
      } catch (e) {}
      return {
        icon
      };
    },
    getCabinetPageMarkerOptions(marker) {
      let icon, circuit_number;
      try {
        const metaDevice = marker['meta.device'];
        circuit_number = Utils.hasTondoClass(marker.class_name)
          ? metaDevice.circuit_number
          : '';

        icon = this.calculateMarkerIcon(marker, metaDevice, 'other');
      } catch (e) {}

      return {
        icon,
        label:
          (this.showCircuitNumbers && circuit_number && {
            color: '#DA0AAA',
            fontWeight: 'bold',
            fontSize: '14px',
            text: circuit_number.toString()
          }) ||
          ''
      };
    },
    calculateMarkerIcon(marker, meta_device, layer) {
      let marker_icon = 'regular';
      const circuit_number_value = meta_device.circuit_number;
      const circuit_number = circuit_number_value ? circuit_number_value : null;
      if (Utils.hasTondoClass(marker.class_name) && meta_device) {
        marker_icon =
          (meta_device.fixture_icon &&
            meta_device.fixture_icon.toLowerCase()) ||
          marker_icon;
      } else if (Utils.hasVirtualCabinetClass(marker.class_name, marker['meta.device'])) {
        marker_icon = 'virtual';
      }
      if (
        this.group &&
        this.groupDevices &&
        this.groupDevices.size &&
        this.groupDevices.has(marker.id)
      ) {
        marker_icon = `selected_${marker_icon}`;
      }
      return {
        url: this.getMarkerIcon(marker, marker_icon, circuit_number, layer),
        scaledSize: Utils.hasWeatherStationClass(marker.class_name) ? new google.maps.Size(22, 22) : new google.maps.Size(18, 18)
      };
    },
    getMarkerIcon(marker, marker_icon, circuit_number, layer) {
      if (Utils.hasAssetClass(marker.class_name))
        return marker.sys___active === 0
          ? config.icons.virtualElectricalFault
          : marker.power > 0
          ? config.icons.virtualOn
          : config.icons.virtualOff;

      if (marker['meta.category.category'] === 'RADAR')
        return config.icons.radarOk;

      if (Utils.hasFloodSensorClass(marker.class_name, marker['meta.category.category'])) {
        return marker[FloodSensor.BOTTOM_FLOAT_TRIGGER] === 1 && marker[FloodSensor.TOP_FLOAT_TRIGGER] === 1 && marker[FloodSensor.ULRASONIC_SENSOR_TRIGGER] === 1
            ? config.icons.floodSensorAlert
              : marker[FloodSensor.ULRASONIC_SENSOR_TRIGGER] === 1
                ? config.icons.topSensorAlert
                : marker[FloodSensor.BOTTOM_FLOAT_TRIGGER] === 1 || marker[FloodSensor.TOP_FLOAT_TRIGGER] === 1
                  ? config.icons.bottomSensorAlert
                  : config.icons.floodSensor;
      }

      if (Utils.hasMotionSensorClass(marker.class_name)) {
        return config.icons.sensorOk;
      }

      if (Utils.hasBarrierClass(marker.class_name, marker['meta.category.category'])) {
        return marker.power > 0
          ? config.icons.alertBarrier
          : config.icons.openBarrier;
      }

      if (Utils.hasVideoClass(marker.class_name))
        return marker.sys___active === 0
          ? config.icons.cameraFault
          : config.icons.cameraOk;

      if (Utils.hasWaterMeterClass(marker.class_name)) {
        return marker.sys___active ? config.icons.waterMeter : config.icons.waterMeterFault;
      }

      if (Utils.hasWeatherStationClass(marker.class_name)) {
        return config.icons.weatherStation;
      }

      // If device has parent - for lora devices
      if (marker.parent_id) {
        const parent = this.devices.find((v) => v.id === marker.parent_id);
        if (parent) {
          const parentOnline = parent.sys___connected !== 0;
          // check if parent is offline
          if (!parentOnline) {
            return parent.power > 0
              ? config.icons.yellowRedLine
              : marker.power > 0
              ? config.icons.yellowRedLine
              : config.icons.greyRedLine;
          }
        }
      }

      if (Utils.hasVirtualCabinetClass(marker.class_name, marker['meta.device'])) {
        return config.icons.virtualCabinet;
      }

      if (Utils.hasCabinetClass(marker.class_name)) {
        if (marker.sys___active === -3)
          return config.icons.cabinetInInstallation;

        return marker.sys___active === 0
          ? config.icons.regularCabinetFault
          : this.$store.state.ProjectStreams.digital_input_by_cabinet.get(marker.id) &&
            this.$store.state.ProjectStreams.digital_input_by_cabinet.get(marker.id)['15']
            ? config.icons.regularCabinetOn
            : config.icons.regularCabinetOff;
      }

      if (marker.class_name === 'Lora_BLE_nRF52V3') {
        return marker.power > 0
          ? marker.sys___online === 0
            ? config.icons.yellowRedLine
            : config.icons.regularOn
          : marker.sys___online === 0
          ? config.icons.greyRedLine
          : config.icons.regularOff;
      }
      // if (marker['meta.category.category'] === 'CONTAINER')
      //   return config.icons.container;
      if (marker.sys___active === -1)
        return marker_icon.includes('selected')
          ? config.icons['selected_regularInMaintenance']
          : config.icons.regularInMaintenance;
      if (marker.sys___active === -3)
        return marker_icon.includes('selected')
          ? config.icons['selected_regularInInstallation']
          : config.icons.regularInInstallation;
      if (marker.sys___active === -9)
        return marker_icon.includes('selected')
          ? config.icons['selected_regularInCare']
          : config.icons.regularInCare;

      if (layer === 'simple') {
        let related_cabinet = null;
        if (marker['meta.device'].cabinet_id){
          related_cabinet = this.$store.getters['Reports/commissionedCabinets']
            .find((cabinet) => cabinet['meta.device'].cabinet_id === marker['meta.device'].cabinet_id);
        }

        if (!related_cabinet) return config.icons.regularInInstallation;
      }

      if (marker_icon.includes('virtual')) {
        return marker.power
          ? config.icons[`${marker_icon}On`]
          : config.icons[`${marker_icon}Off`];
      }

      if (this.$store.state.Global.hasElectricalPower){
        return layer === 'simple'
          ? this.getSimpleModeWithElectricalIcon(marker, marker_icon, circuit_number)
          : this.getAdvancedModeIcon(marker, marker_icon);
      }else {
        return layer === 'simple'
          ? this.getSimpleModeIcon(marker, marker_icon, circuit_number)
          : this.getRegularModeIcon(marker, marker_icon);
      }
    },
    getAdvancedModeIcon(marker, marker_icon) {
      if (marker.low_power_alert)
        return config.icons[`${marker_icon}BurntFault`];

      if (!marker.sys___active && marker.disconnect_alert === 1)
        return config.icons[`${marker_icon}ElectricalFault`];
      if (
        (!marker.sys___active && marker.disconnect_alert !== 1) ||
        (marker.sys___active && !marker.sys___connected)
      ) {
        return this.checkNotCommunictedIsOn(marker)
          ? config.icons[`${marker_icon}CommFaultOn`]
          : config.icons[`${marker_icon}CommFaultOff`];
      }
      if (marker.sys___connected)
        return marker.power
          ? config.icons[`${marker_icon}On`]
          : config.icons[`${marker_icon}Off`];
    },
    getRegularModeIcon(marker, marker_icon) {
      if (marker.sys___active) {
        return marker.sys___connected
          ? marker.power
            ? config.icons[`${marker_icon}On`]
            : config.icons[`${marker_icon}Off`]
          : config.icons[`${marker_icon}VoltageFault`];
      }

      return config.icons[`${marker_icon}ElectricalFault`];
    },
    getSimpleModeWithElectricalIcon(marker, marker_icon, circuit_number) {
      if (!marker.sys___active && marker.disconnect_alert === 1)
        return config.icons[`${marker_icon}ElectricalFault`];
      if (
        (!marker.sys___active && marker.disconnect_alert !== 1) ||
        (marker.sys___active && !marker.sys___connected)
      ) {
        return this.checkPartialCommSimpleMode(marker, marker_icon, circuit_number);
      }
      if (marker.sys___connected)
        return marker.power
          ? config.icons[`${marker_icon}On`]
          : config.icons[`${marker_icon}Off`];
    },
    getSimpleModeIcon(marker, marker_icon, circuit_number) {
      if (marker.sys___active) {
        return marker.sys___connected
          ? marker.power
            ? config.icons[`${marker_icon}On`]
            : config.icons[`${marker_icon}Off`]
          : this.checkPartialCommSimpleMode(marker, marker_icon, circuit_number);
      }

      return config.icons[`${marker_icon}ElectricalFault`];
    },
    checkPartialCommSimpleMode(marker, marker_icon, circuit_number){
      const related_cabinet = this.$store.getters['Reports/commissionedCabinets']
        .find((cabinet) => cabinet['meta.device'].cabinet_id === marker['meta.device'].cabinet_id);

      let percent = 0;
      try {
        percent = circuit_number === null
          ? related_cabinet.device_connected_percent 
          : JSON.parse(related_cabinet.circuits.replaceAll(`'`, `"`))[`c_${circuit_number}_connection_grade`];
      }catch (e){
        percent = related_cabinet.device_connected_percent;
      }
      if (typeof percent !== 'number') percent = related_cabinet.device_connected_percent;
      if (percent <= 50) return config.icons[`${marker_icon}Off`];

      return this.checkNotCommunictedIsOn(marker)
        ? config.icons[`${marker_icon}On`]
        : config.icons[`${marker_icon}Off`];
    },
    checkNotCommunictedIsOn(device) {
      let power_on = true;
      try {
        let device_schedule = PropUtils.parseProperty('meta.schedule_curve', device);
        device_schedule =
          Object.keys(device_schedule).length && device_schedule.open_schedule
            ? device_schedule.open_schedule
            : device_schedule;
        if (Object.keys(device_schedule).length) {
          const today_schedules = device_schedule[this.day_of_week]
            ? device_schedule[this.day_of_week].map((schedule) => ({
                day_of_week: this.day_of_week,
                ...schedule
              }))
            : [];
          const yesterday_schedules = device_schedule[
            this.day_of_week - 1 || 7
          ]
            ? device_schedule[this.day_of_week - 1 || 7].map(
                (schedule) => ({
                  day_of_week: this.day_of_week - 1,
                  ...schedule
                })
              )
            : [];
          const final_schedules = yesterday_schedules.concat(today_schedules);
          power_on = final_schedules.some((decimal_schedule) =>
            this.checkIsOn(decimal_schedule)
          );
        }
      } catch (e) {}

      return power_on;
    },
    checkIsOn(decimal_schedule) {
      const schedule = this.getScheduleRanges(decimal_schedule);
      const is_on =
        schedule.start <= +localStorage.getItem('now') &&
        schedule.end >= +localStorage.getItem('now') &&
        schedule.power > 0;
      return is_on;
    },
    getScheduleRanges(decimal_schedule) {
      const day = decimal_schedule.day_of_week;
      const duration = decimal_schedule.duration;
      const power = decimal_schedule.power;
      const start_time = decimal_schedule.start_time_minutes;
      const end_time = start_time + duration;

      let start_timestamp, end_timestamp;
      const [start_string, start_after_00] =
        this.convertMinutesToHours(start_time);
      const [end_string, end_after_00] = this.convertMinutesToHours(end_time);
      if (day === this.day_of_week) {
        start_timestamp = start_after_00
          ? Utils.getDateWithSpecificTime(start_string, this.tommorow)
          : Utils.getDateWithSpecificTime(
              start_string,
              +localStorage.getItem('now')
            );
        end_timestamp = end_after_00
          ? this.today_23
          : Utils.getDateWithSpecificTime(
              end_string,
              +localStorage.getItem('now')
            );
      } else {
        start_timestamp = start_after_00
          ? Utils.getDateWithSpecificTime(
              start_string,
              +localStorage.getItem('now')
            )
          : this.today_00;
        end_timestamp = end_after_00
          ? Utils.getDateWithSpecificTime(
              end_string,
              +localStorage.getItem('now')
            )
          : Utils.getDateWithSpecificTime(end_string, this.yesterday);
      }

      return {
        start: start_timestamp,
        end: end_timestamp,
        power
      };
    },
    convertMinutesToHours(minutes) {
      let after_00 = false;
      let hour = Math.floor(minutes / 60);
      if (hour > 23) {
        after_00 = true;
        hour = hour - 24;
        hour = hour < 10 ? `0${hour}` : `${hour}`;
      }
      let remain = minutes % 60;
      remain = remain < 10 ? `0${remain}` : `${remain}`;
      return [`${hour}:${remain}`, after_00];
    },
    showAdvancedMap(){
      const isAdvancedMapClicked = !this.advancedMap;
      this.$emit('setAdvancedMap');

      if (this.showSettings) this.showSettings = false;
      if (!isAdvancedMapClicked && this.deviceDrag) this.toggleDragMarkers();
      if (!isAdvancedMapClicked && this.deviceSelectorChecked) this.clearDeviceSelector();

      this.showAdvancedLocations = false;
      this.clearPolylines();
      this.clearAdvancedLocations();
      isAdvancedMapClicked 
        ? this.overrideFeaturesStyle('advanced')
        : this.overrideFeaturesStyle('simple');
    },
    overrideFeaturesStyle(layer_name){
      if (layer_name === 'simple'){
        this.map_layers.get('markers').forEach((feature) => {
          const options = this.getMarkerOptions(this.markers_map.get(feature.getId()), 'simple');
          const properties = {icon: options.icon, zIndex: feature.getProperty('zIndex'), draggable: false};
          this.map_layers.get('markers').overrideStyle(feature, properties);
        });
      }else {
        this.map_layers.get('markers').forEach((feature) => {
          const options = this.getMarkerOptions(this.markers_map.get(feature.getId()), 'advanced');
          const properties = {icon: options.icon, zIndex: feature.getProperty('zIndex'), draggable: false};
          this.map_layers.get('markers').overrideStyle(feature, properties);
        });
      }
      this.map_layers.get('markers').setMap(this.map);
    },
    clearMapData(){
      if (this.filteredDevicesRoute) {
        this.removeAllLayerFeatures('markers');
        this.markers_map = new Map();
        this.markers = [];
      }
      this.isDefaultCabinet = false;
      this.showAdvancedLocations = false;

      this.clearPolylines();
      this.clearAdvancedLocations();
    },
    getCommissionedDevices(){
      let commissioned = this.getInstalledWithValidLocation();
      if (this.unassigned){
        this.isDefaultCabinet = true;
        commissioned = commissioned.filter((device) => this.isMarkerInsideMapBounds(device));
      }
      return commissioned;
    },
    setMarkerInformation() {
      this.simpleMap = this.$route.path === '/';

      this.setMapLayers();
      this.clearMapData();
      this.setMarkersMapData();
      this.markers = [...this.markers_map.values()];
      this.setMapLayersStyles();
      this.setMapLayersListeners();
      if (this.markers.length && this.cabinetPage && !this.unassigned || !this.cabinetPage) this.setMapBounds(this.markers);
      this.map_layers.get('markers').setMap(this.map);
    },
    asyncSetMarkerInfo() {
      return new Promise((resolve) => resolve(this.setMarkerInformation()));
    },
    async syncSetMarkerInfo(){
      await this.asyncSetMarkerInfo();
    },
    setMapLayersStyles(){
      this.addDataLayerStyles(this.map_layers.get('markers'), 2);
      this.addDataLayerStyles(this.map_layers.get('gps'), 1);
      this.addDataLayerStyles(this.map_layers.get('gis'), 1);
      this.addDataLayerStyles(this.map_layers.get('app'), 1);
    },
    setMapLayersListeners(){
      this.addDataLayerListeners(this.map_layers.get('markers'), 'markers');
    },
    removeMapLayersListeners(){
      this.removeLayerListener(this.map_layers.get('markers'), 'markers');
    },
    removeLayerListener(layer, layer_name){
      if (this.checkLayerHasListener(layer, layer_name, 'click')){
        google.maps.event.removeListener(this.map_layers_listeners.get(`${layer_name}_click`));
      }
      if (this.checkLayerHasListener(layer, layer_name, 'mouseup')){
        google.maps.event.removeListener(this.map_layers_listeners.get(`${layer_name}_mouseup`));
      }
      if (this.checkLayerHasListener(layer, layer_name, 'mousedown')){
        google.maps.event.removeListener(this.map_layers_listeners.get(`${layer_name}_mousedown`));
      }
      if (this.checkLayerHasListener(layer, layer_name, 'mouseout')){
        google.maps.event.removeListener(this.map_layers_listeners.get(`${layer_name}_mouseout`));
      }
      if (this.checkLayerHasListener(layer, layer_name, 'mouseover')){
        google.maps.event.removeListener(this.map_layers_listeners.get(`${layer_name}_mouseover`));
      }
      this.map_layers_listeners = new Map();
    },
    checkLayerHasListener(layer, layer_name, event_name){
      return google.maps.event.hasListeners(layer, event_name) &&
        this.map_layers_listeners.has(`${layer_name}_${event_name}`) &&
        this.map_layers_listeners.get(`${layer_name}_${event_name}`);
    },
    setMarkersMapData(){
      const commissioned = this.getCommissionedDevices();
      commissioned.forEach((device) => {
        const marker = device;
        this.setExtraDeviceData(marker);
        this.simpleMap && !this.advancedMap
          ? this.addFeature(marker, this.map_layers.get('markers'), 'simple')
          : this.addFeature(marker, this.map_layers.get('markers'), 'advanced');

        this.markers_map.set(marker.id, marker);
      });
    },
    setExtraDeviceData(marker){
      if (Utils.hasTondoClass(marker.class_name)) {
        this.setExtraFixtureData(marker);
      } else if (Utils.hasFloodSensorClass(marker.class_name, marker['meta.category.category'])){
        this.setExtraFloodSensorData(marker);
      }
    },
    setExtraFixtureData(marker){
      marker.disconnect_alert = !marker.hasOwnProperty('disconnect_alert') ? 0 : +marker.disconnect_alert;
      marker.low_power_alert = !marker.hasOwnProperty('low_power_alert') ? 0 : +marker.low_power_alert;
      if (marker.hasOwnProperty('meta.install_location') && marker['meta.install_location']) this.setInstallLocationData(marker);
    },
    setExtraFloodSensorData(marker){
      marker[FloodSensor.BOTTOM_FLOAT_TRIGGER] = !marker.hasOwnProperty(FloodSensor.BOTTOM_FLOAT_TRIGGER) ? 0 : +marker[FloodSensor.BOTTOM_FLOAT_TRIGGER];
      marker[FloodSensor.TOP_FLOAT_TRIGGER] = !marker.hasOwnProperty(FloodSensor.TOP_FLOAT_TRIGGER) ? 0 : +marker[FloodSensor.TOP_FLOAT_TRIGGER];
      marker[FloodSensor.ULRASONIC_SENSOR_TRIGGER] = !marker.hasOwnProperty(FloodSensor.ULRASONIC_SENSOR_TRIGGER) ? 0 : +marker[FloodSensor.ULRASONIC_SENSOR_TRIGGER];
    },
    setDataMapLayer(name){
      if (this.map_layers.has(name)) {
        return;
      }
      const layer = new google.maps.Data();
      this.map_layers.set(name, layer);
    },
    setInstallLocationData(marker){
      let install_location_prop = PropUtils.parseProperty('meta.install_location', marker);
      if (!install_location_prop || typeof install_location_prop !== 'object'){
        install_location_prop = {
          gps_location: { lat: '', lng: '' },
          app_location: { lat: '', lng: '' },
          gis_location: { lat: '', lng: '' },
          manual_location: { lat: '', lng: '' }
        };
        marker['meta.install_location'] = install_location_prop;
      }

      if (install_location_prop.hasOwnProperty('gps_location') && Utils.isValidLocation(install_location_prop['gps_location'])){
        const coords = {lat: parseFloat(install_location_prop['gps_location'].lat), lng: parseFloat(install_location_prop['gps_location'].lng)};
        this.addCircleFeature(`gps_${marker.id}`, coords, 'gpsCircle', 24, 'gps');
      }
      if (install_location_prop.hasOwnProperty('gis_location') && Utils.isValidLocation(install_location_prop['gis_location'])){
        const coords = {lat: parseFloat(install_location_prop['gis_location'].lat), lng: parseFloat(install_location_prop['gis_location'].lng)};
        this.addCircleFeature(`gis_${marker.id}`, coords, 'gisCircle', 28, 'gis');
      }
      if (install_location_prop.hasOwnProperty('app_location') && Utils.isValidLocation(install_location_prop['app_location'])){
        const coords = {lat: parseFloat(install_location_prop['app_location'].lat), lng: parseFloat(install_location_prop['app_location'].lng)};
        this.addCircleFeature(`app_${marker.id}`, coords, 'appCircle', 32, 'app');
      }
    },
    addCircleFeature(id, coordinates, circle_name, icon_size, layer_name){
      const icon = {
        url: config.icons[circle_name],
        scaledSize: new google.maps.Size(icon_size, icon_size)
      };
      const feature = new google.maps.Data.Feature({
        id,
        geometry: new google.maps.Data.Point({...coordinates}),
        properties: {
          icon
        }
      });
      this.map_layers.get(layer_name).add(feature);
    },
    addFeature(marker, layer, layer_name){
      const options = this.cabinetPage && this.getCabinetPageMarkerOptions(marker) || this.getMarkerOptions(marker, layer_name);
      const feature = new google.maps.Data.Feature({
        id: marker.id,
        geometry: new google.maps.Data.Point({lat: marker['meta.location'].lat, lng: marker['meta.location'].lng}),
        properties: {
          icon: options.icon
        }
      });
      if (this.cabinetPage && !this.isDefaultCabinet) feature.setProperty('label', options.label);
      layer.add(feature);
    },
    addDataLayerStyles(layer, zIndex){
      layer.setStyle((feature) => {
        const styles = {icon: feature.getProperty('icon'), zIndex, draggable: false};
        if (feature.getProperty('label') !== undefined) styles.label = feature.getProperty('label');
        return styles;
      });
    },
    addDataLayerListeners(layer, layer_name){
      this.addClickEventToLayer(layer, layer_name);
      this.addMouseupEventToLayer(layer, layer_name);
      this.addMousedownEventToLayer(layer, layer_name);
      this.addMouseoverEventToLayer(layer, layer_name);
      this.addMouseoutEventToLayer(layer, layer_name);
    },
    addClickEventToLayer(layer, layer_name){
      if (!google.maps.event.hasListeners(layer, 'click')) {
        const click_event = layer.addListener('click', (event) => {
          if (event.feature.getProperty('draggable')) this.setIsDraggable(event.feature, false);
          if (this.deviceDrag) this.toggleDragMarkers();
          if (this.showAdvancedLocations) this.setAdvancedLocationsIsOpen();
          this.clickMarker(this.markers_map.get(event.feature.getId()));
        });
        this.map_layers_listeners.set(`${layer_name}_click`, click_event);
      }
    },
    addMouseupEventToLayer(layer, layer_name){
      if (!google.maps.event.hasListeners(layer, 'mouseup')){
        const mouseup_event = layer.addListener('mouseup', (event) => {
        if (this.deviceDrag) {
          const point = event.feature.getGeometry();
          this.dragCurrentLocation = {lat: point.get().lat(), lng: point.get().lng()};
          if (!isEqual(this.dragPrevLocation, this.dragCurrentLocation)){
            this.dragOnly && !this.$route.path.includes('virtual-devices')
              ? this.$emit('dragend', this.dragMarker, this.dragCurrentLocation, this.dragPrevLocation)
              : this.confirmDragDialog = true;
          }
        }
        });
        this.map_layers_listeners.set(`${layer_name}_mouseup`, mouseup_event);  
      }
    },
    addMousedownEventToLayer(layer, layer_name){
      if (!google.maps.event.hasListeners(layer, 'mousedown')){
        const mousedown_event = layer.addListener('mousedown', (event) => {
          if (this.deviceDrag) {
            if (this.infoOpenedCurrent) this.closeInfoWindow();
            this.dragMarker = this.markers_map.get(event.feature.getId());
            const point = event.feature.getGeometry();
            this.dragPrevLocation.lat = point.get().lat();
            this.dragPrevLocation.lng = point.get().lng();
          }
        });
        this.map_layers_listeners.set(`${layer_name}_mousedown`, mousedown_event);
      }
    },
    addMouseoverEventToLayer(layer, layer_name){
      if (!google.maps.event.hasListeners(layer, 'mouseover')){
        const mouseover_event = layer.addListener('mouseover', (event) => {
          if (this.deviceDrag && !this.draggableFeatures.has(event.feature.getId())) {
            this.setIsDraggable(event.feature, true);
            this.draggableFeatures.set(event.feature.getId(), true);
          }
          if (!this.current_device && this.showAdvancedLocations){
            this.addPolylines(this.markers_map.get(event.feature.getId()));
          }else if (!this.current_device && !this.showAdvancedLocations && !this.dragMarker) {
            this.showCurrentInfoWindow(this.markers_map.get(event.feature.getId()));
          }
        });
        this.map_layers_listeners.set(`${layer_name}_mouseover`, mouseover_event);
      }
    },
    addMouseoutEventToLayer(layer, layer_name){
      if (!google.maps.event.hasListeners(layer, 'mouseout')){
        const mouseout_event = layer.addListener('mouseout', (event) => {
          if (!this.current_device && this.showAdvancedLocations) this.clearPolylines();
          if (!this.current_device && !this.showAdvancedLocations) this.closeInfoWindow();
        });
        this.map_layers_listeners.set(`${layer_name}_mouseout`, mouseout_event);
      }
    },
    setIsDraggable(feature, draggable){
      const marker = this.markers_map.get(feature.getId());
      const options = this.cabinetPage && this.getCabinetPageMarkerOptions(marker) || this.getMarkerOptions(marker, 'advanced');
      
      const properties = {icon: options.icon, zIndex: feature.getProperty('zIndex'), draggable};
      if (feature.getProperty('label') !== undefined) properties.label = feature.getProperty('label');
      this.map_layers.get('markers').overrideStyle(feature, properties);
    },
    updateFeatureIcon(marker){
      this.$nextTick(() => {
        const feature = this.map_layers.get('markers').getFeatureById(marker.id);
        const layer_name = this.simpleMap && !this.advancedMap ? 'simple' : 'advanced';
        const options = this.cabinetPage && this.getCabinetPageMarkerOptions(marker) || this.getMarkerOptions(marker, layer_name);
        const properties = {icon: options.icon, zIndex: feature.getProperty('zIndex'), draggable: feature.getProperty('draggable')};
        if (feature.getProperty('label') !== undefined) properties.label = options.label;
        this.map_layers.get('markers').overrideStyle(feature, properties);
      });
    },
    closeInfoWindow(){
      this.closeWindow();
      if (!this.group && !this.dragOnly) this.clearDeviceData(); 
    },
    closeWindow(){
      this.infoPositionCurrent = null;
      this.infoOpenedCurrent = false;
      this.infoContentCurrent = null;
    },
    setMapBounds(locations) {
      if (locations && locations.length && this.$store.state.Global.deviceClicked) {
        this.clickExternalDevice(this.$store.state.Global.deviceClicked);
      } else {
        this.group && this.groupDevices && this.groupDevices.size
        ? this.setBounds([...this.groupDevices.values()])
        : this.setBounds(locations || []);
      }
    },
    clickExternalDevice(id){
      const marker = this.getMarkerById(id);
      if (marker) {
        this.setBounds([{lat: marker.lat, lng: marker.lng}]);
      }
      this.clickMarker(marker);
    },
    getMarkerById(id){
      return this.markers_map.get(id);
    },
    getInstalledWithValidLocation() {
      return this.devices.filter((device) => !this.markers_map.has(device.id)).filter((device) => {
        if ((device['meta.location'] && (this.$store.getters['Reports/commissioned_map'].has(device.id)) || this.dragOnly)) {
          if (device.has_valid_location) {
            if ((Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device'])) && this.cabinetPage) {
              const cabinet_id_value = device['meta.device'].cabinet_id;
              const cabinet_id = cabinet_id_value ? cabinet_id_value.toString() : '';
              if (cabinet_id === 'default') this.isDefaultCabinet = true;
            }
            return true;
          }
        }
        return false;
      });
    },
    // getContainerMarker(device, container_id, coordinates) {
    //   return {
    //     id: device.id,
    //     container_id,
    //     class_name: 'CONTAINER',
    //     ['meta.category.category']: 'CONTAINER',
    //     ['meta.location']: { lat: coordinates.lat, lng: coordinates.lng },
    //     ['meta.device']: device['meta.device'],
    //     lat: coordinates.lat,
    //     lng: coordinates.lng
    //   };
    // },
    clickMarker(marker) {
      if (!marker) return;
      
      if (this.showSelectionTools && this.deviceSelectorChecked || this.$route.path.includes('/groups/')) {
        this.$emit('markerClicked', [marker]);
        return;
      }

      if (Utils.isCabinet(marker)) {
        this.$router.push(`/cabinet-details/${marker.id}`);
        return;
      }
      // this.$emit('containerClicked', false);
      // this.$emit('containerClicked', marker.class_name === 'CONTAINER');

      this.$emit('showDeviceInformation', marker);
      this.center = {lat: marker.lat, lng: marker.lng};
      this.setSearchClicked(false);
      setTimeout(() => this.showCurrentInfoWindow(marker), this.$store.state.Global.deviceClicked ? 500 : 0);
    },
    initSearch() {
      const input = document.getElementById('search-input');
      this.$nextTick(() => {
        this.$refs.map.$mapPromise.then((map) => {
          this.map = map;
          this.setMapLayers();

          if (this.showSelectionTools) this.selectMarkersManager();
          Vue.set(this.mapOptions, 'zoomControlOptions', {
            position: google.maps.ControlPosition.LEFT_BOTTOM
          });
          Vue.set(this.mapOptions, 'streetViewControl', {
            position: google.maps.ControlPosition.LEFT_BOTTOM
          });
          if (input) {
            const searchBox = new window.google.maps.places.SearchBox(input);
            searchBox.addListener('places_changed', () => {
              const places = searchBox.getPlaces();
              if (places.length !== 0) {
                const location = places[0].geometry.location;
                const position = {
                  lat: location.lat(),
                  lng: location.lng()
                };
                map.panTo(position);
                this.smoothZoom(18, this.map.getZoom());
              }
            });
          }
          const thePanorama = this.map.getStreetView();
          thePanorama.addListener('visible_changed', () => {
            this.showLegend = !thePanorama.getVisible();
          });
          // add markers after map is done loading tiles
          const tilesloaded = map.addListener('tilesloaded', async () => {
            if (this.$store.state.Global.deviceClicked) this.$store.commit('Global/setDeviceClicked', '');
            google.maps.event.removeListener(tilesloaded);
          });
          // if (this.fullviewPage){
          //   const zoom = map.addListener('zoom_changed', () => {
          //     const current_zoom = this.map.getZoom();
          //     if (current_zoom >= 15 && this.map_layers.get('cabinets_devices').getMap() === null){
          //       this.map_layers.get('cabinets_devices').setMap(this.map);
          //     }else if (current_zoom < 15 && this.map_layers.get('cabinets_devices').getMap() !== null){
          //       this.map_layers.get('cabinets_devices').setMap(null);
          //       if (this.$store.state.Global.deviceClicked) this.closeInfoWindow();
          //     }
          //   });
          // }
          
          // map.addListener('bounds_changed', async () => {
          //   await this.setMapCurrentBounds();
          // });
        });
      });
    },
    // async setMapCurrentBounds(){
    //   const bounds = this.map.getBounds();
    //   if (bounds){
    //     const ne = bounds.getNorthEast();
    //     const sw = bounds.getSouthWest();
    //     if (ne.lat() && sw.lat() && ne.lng() && sw.lng()) {
    //       const hiLat = await ne.lat();
    //       const loLat = await sw.lat();
    //       const hiLng = await ne.lng();
    //       const loLng = await sw.lng();
    //       this.mapCurrentBounds = { hiLat, loLat, hiLng, loLng };
    //     }
    //   }
    // },
    drawPolygon(points) {
      if (points.length < 3) return;
      // first delete the previous polygon
      if (this.polygon) this.polygon.setMap(null);
      // @see https://developers.google.com/maps/documentation/javascript/examples/polygon-simple
      this.polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.$refs.map.$mapObject
      });
      // display to input
      this.displaySelectedMarkers();
    },
    displaySelectedMarkers() {
      const poly = this.google && google.maps.geometry.poly;
      // @see https://developers.google.com/maps/documentation/javascript/examples/poly-containsLocation
      this.polygonDevices = this.markers.filter((marker) =>
        poly.containsLocation(new google.maps.LatLng(marker['meta.location'].lat, marker['meta.location'].lng), this.polygon)
      );
    },
    addDevices(state = false) {
      this.$emit('selectedChanged', this.polygonDevices, state);
      this.deviceSelectorChecked = false;
      this.clearPolySelection();
    },
    addSelectedDevices(state = null) {
      this.$emit('selectedChanged', this.polygonDevices, state);
      this.clearPolySelection();
    },
    clearPolySelection() {
      if (this.polygon) this.polygon.setMap(null);
      this.polygonMarkers.forEach((polygon_marker) => polygon_marker.setMap(null));
      this.polygonMarkers = [];
      this.polygonLocations = [];
      this.polygonDevices = [];
    },
    handleKeyPress(e){
      if (e.code === 'Delete' && this.deviceSelectorChecked && this.polygonDevices.length > 0) {
        this.deviceSelectorChecked = false;
        this.clearPolySelection();
      }
    },
    handleMapClick(e){
      if (this.deviceSelectorChecked) {
        const position = e.latLng;
        this.polygonLocations.push(position);
        this.polygonMarkers.push(
          new google.maps.Marker({
            icon: config.icons.measle,
            position,
            map: this.$refs.map.$mapObject
          })
        );
        this.drawPolygon(this.polygonLocations);
      }
    },
    selectMarkersManager() {
      if (google.maps.event.hasListeners(this.map, 'click')) return;

      document.addEventListener('keypress', this.handleKeyPress);
      this.map.addListener('click', this.handleMapClick);
    },
    isMarkerInsideMapBounds(marker){
      return this.$refs.map.$mapObject.getBounds().contains({lat: marker['meta.location'].lat, lng: marker['meta.location'].lng});
    },
    showCurrentInfoWindow(marker) {
      this.infoPositionCurrent = { lat: marker['meta.location'].lat, lng: marker['meta.location'].lng };
      this.infoContentCurrent = this.getMarkerText(marker);
      this.infoOpenedCurrent = true;
    },
    addPolylines(marker){
      this.clearPolylines();
      if (marker.hasOwnProperty('meta.install_location')) {
        const pathstart = [new google.maps.LatLng(marker['meta.location'].lat, marker['meta.location'].lng)];
        if (this.advancedLocations['gps']){
          const gps_feature = this.map_layers.get('gps').getFeatureById(`gps_${marker.id}`);
          if (gps_feature) this.setPolylinePath('polyline', 'green', gps_feature, pathstart);
        }
        if (this.advancedLocations['gis']){
          const gis_feature = this.map_layers.get('gis').getFeatureById(`gis_${marker.id}`);
          if (gis_feature) this.setPolylinePath('polyline1', 'red', gis_feature, pathstart);
        }
        if (this.advancedLocations['app']){
          const app_feature = this.map_layers.get('app').getFeatureById(`app_${marker.id}`);
          if (app_feature) this.setPolylinePath('polyline2', 'purple', app_feature, pathstart);
        }
      }
    },
    setPolylinePath(polyline, color, feature, pathstart){
      this[polyline] = this.createPolyline(color);
      const geometry = feature.getGeometry();
      const path = [...pathstart, new google.maps.LatLng(geometry.get().lat(), geometry.get().lng())];
      this[polyline].setPath(path);
      this[polyline].setMap(this.map);
    },
    createPolyline(strokeColor){
      return new google.maps.Polyline({
        strokeColor,
        strokeOpacity: 0.4
      });
    },
    getMarkerText(device) {
      const newDevice = deviceFactory.createDevice(device);
      return newDevice.getPopUpText();
    },
    setMarkerCoords(location, marker_id) {
      const marker = this.markers_map.get(marker_id);
      marker['meta.location'] = location;
      marker.lat = location.lat;
      marker.lng = location.lng;
    },
    setMapStyles(name) {
      if (this.hasCustomMapStyles) {
        const copy_map_styles = cloneDeep(this.customMapStyles);
        let defaultStyle = mapStyles['defaultMode'];
        Object.entries(copy_map_styles).forEach(([map_type, style]) => {
          style[0].default = this.mapMode === map_type;
          if (style[0].default) defaultStyle = style[0].style[0];
        });

        const prop = {
          name: 'dashboard.map_styles',
          objectID: this.$store.state.User.project.id,
          objectType: Types.PROJECTS
        };

        this.$refs.propEditor.save(
          copy_map_styles,
          prop,
          async () => {
            Object.entries(this.customMapStyles).forEach(([map_type, style]) => style[0].default = this.mapMode === map_type);
            // localStorage.setItem('mapMode', this.mapMode);
            Vue.set(this.mapOptions, 'styles', defaultStyle);
          },
          'Default style changed'
        );
      } else {
        // localStorage.setItem('mapMode', this.mapMode);
        Vue.set(this.mapOptions, 'styles', mapStyles[this.mapMode]);
      }
      this.toggleMapSettings();
    },
    setMapStyle(field_name, value) {
      Vue.set(this.mapStyles, field_name, value);
    },
    toggleSearch() {
      if (this.showSettings) this.showSettings = false;
      this.openSearch = !this.openSearch;
      this.search_value = '';
    },
    toggleMapSettings() {
      this.showSettings = !this.showSettings;
    },
    toggleDragMarkers() {
      if (this.showSettings) this.showSettings = false;
      this.$store.commit('MapSettings/setDeviceRepositioning', !this.deviceDrag );
      this.dragMarker = null;
      this.clearDragLocations();
      if (!this.deviceDrag){
        if (this.confirmDragDialog) this.confirmDragDialog = false;
        if (this.draggableFeatures.size){
          [...this.draggableFeatures.keys()].forEach((feature_id) => {
            const feature = this.map_layers.get('markers').getFeatureById(feature_id);
            this.setIsDraggable(feature, false);
          });
          this.draggableFeatures = new Map();
        }
      }
    },
    setDefaultProjectMapStyle() {
      this.hasCustomMapStyles = false;
      this.styleList = defaultStyleSheet;
      this.mapMode = 'defaultMode';
      Vue.set(this.mapOptions, 'styles', mapStyles[this.mapMode]);
    },
    getProjectMapStyles() {
      try {
        const styleList = [{ name: 'Default', value: 'defaultMode' }];
        const map_styles = PropUtils.getProperty(this.projectProperties, 'dashboard.map_styles');
        let defaultType = 'defaultMode';
        let defaultStyle = mapStyles['defaultMode'];
        if (map_styles && map_styles.value) {
          this.customMapStyles = map_styles.value;
          Object.entries(this.customMapStyles).forEach(([map_type, style]) => {
            const current_style = style[0];
            if (current_style.default) {
              defaultType = map_type;
              defaultStyle = current_style.style[0];
            }
            styleList.push({name: map_type, value: map_type});
          });
        }
        if (styleList.length > 1) {
          this.hasCustomMapStyles = true;
          this.mapMode = defaultType;
          this.styleList = styleList;
          // localStorage.setItem('mapMode', defaultType);
          Vue.set(this.mapOptions, 'styles', defaultStyle);
        } else {
          this.setDefaultProjectMapStyle();
        }
      } catch (e) {
        console.log('err', e);
        this.setDefaultProjectMapStyle();
      }
    },
    setBounds(locations) {
      this.$nextTick(() => {
        this.$gmapApiPromiseLazy().then(() => {
          const bounds = new google.maps.LatLngBounds();
          if (!locations.length) {
            bounds.extend(new google.maps.LatLng(this.projectDefaultLocation.lat, this.projectDefaultLocation.lng));
            // logic to change map zoom if we don`t have markers
            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
              const extendPoint1 = new google.maps.LatLng(
                bounds.getNorthEast().lat() + 0.01,
                bounds.getNorthEast().lng() + 0.01
              );
              const extendPoint2 = new google.maps.LatLng(
                bounds.getNorthEast().lat() - 0.01,
                bounds.getNorthEast().lng() - 0.01
              );
              bounds.extend(extendPoint1);
              bounds.extend(extendPoint2);
            }
          }else {
            locations.forEach((location) => {
              bounds.extend(new google.maps.LatLng(location.lat, location.lng));
            });
          }
          if (this.$refs.map) this.$refs.map.fitBounds(bounds);
        });
      });
    },
    linkLayerToMap(layer_name){
      const layer = this.map_layers.get(layer_name);
      Vue.set(this.advancedLocations, layer_name, !this.advancedLocations[layer_name]);
      this.advancedLocations[layer_name]
        ? layer.setMap(this.map)
        : layer.setMap(null);
    },
    clearPolylines(){
      if (this.polyline) this.polyline.setMap(null);
      if (this.polyline1) this.polyline1.setMap(null);
      if (this.polyline2) this.polyline2.setMap(null);
    },
    setDragMarker(value){
      this.dragMarker = value;
    },
    revertDrag(){
      this.confirmDragDialog = false;
      const point = this.map_layers.get('markers').getFeatureById(this.dragMarker.id);
      point.setGeometry(new google.maps.Data.Point({...this.dragPrevLocation}));
      this.dragMarker = null;
      this.clearDragLocations();
    },
    async updateDevicePosition(){
      const drag_marker = cloneDeep(this.dragMarker);
      const current_location = {...this.dragCurrentLocation};
      this.dragMarker = null;
      this.confirmDragDialog = false;
      const prop = {
        name: 'meta.location', // לפרופרטי אחר meta.locationשינוי מיקום מכשיר מ
        value: {},
        objectID: drag_marker.id,
        objectType: Types.DEVICES
      };
      if (this.$route.path.includes('/virtual-devices')) this.$emit('changeVirtualLocation', current_location);
      this.$refs.propEditor.save(
        {lat: current_location.lat.toString(), lng: current_location.lng.toString()},
        prop,
        async () => {
          this.closeInfoWindow();
          this.map_layers.get('markers').getFeatureById(drag_marker.id).setGeometry(new google.maps.Data.Point({...current_location}));
          // if (this.map_layers.has('simple') && this.map_layers.get('simple').getFeatureById(drag_marker.id)){
          //   this.map_layers.get('simple').getFeatureById(drag_marker.id).setGeometry(new google.maps.Data.Point({...current_location}));
          // }
          this.setMarkerCoords(current_location, drag_marker.id);
          if (drag_marker.hasOwnProperty('meta.install_location') && drag_marker['meta.install_location']) {
            this.updateInstallLocationProperty(drag_marker, current_location);
          }
          this.updateMarkerAddress(drag_marker.id, current_location);
          this.clearDragLocations();
        },
        `Device location successfully updated`
      );
    },
    clearDragLocations(){
      this.dragPrevLocation = {lat: null, lng: null};
      this.dragCurrentLocation = {lat: null, lng: null};
    },
    async updateMarkerAddress(device_id, current_location){
      const url = 'device/update_estimated_address';
      const body = {
        device_id,
        company_id: this.$store.state.User.project.company,
        project_id: this.$store.state.User.project.id,
        lat: current_location.lat,
        lng: current_location.lng
      };

      const response = await API.dashboardAPI(url, 'POST', {}, body);
      if (response && response.address) {
        const map_marker = this.markers_map.get(device_id);
        const meta_device = map_marker['meta.device'];
        const address_field = Utils.hasCabinetClass(map_marker.class_name) || Utils.hasVirtualCabinetClass(map_marker.class_name, meta_device) ? 'cabinet_address' : 'address';
        meta_device[address_field] = response.address;
        const uppercase_field = PropUtils.findMatchedUppercaseField(meta_device, address_field);
        if (uppercase_field){
          meta_device[uppercase_field] = meta_device[address_field];
        }
        map_marker['meta.device'] = meta_device;
      }
    },
    async getLastPropertyHistory(property_name, device_id) {
      const property_history = await API.get(
        Types.DEVICES,
        `${device_id}/properties/${property_name}/history`,
        {}
      );
      return property_history.length ? property_history[0] : '';
    },
    async updateInstallLocationProperty(device, new_location) {
      const last_location = this.$store.state.User.role === 'CA'
        ? await this.getLastPropertyHistory('meta.location', device.id)
        : '';
      const install_location_prop = PropUtils.parseProperty('meta.install_location', device);
      install_location_prop.manual_location = {lat: new_location.lat.toString(), lng: new_location.lng.toString()};

      if (last_location) {
        if (last_location.updater.toString() === device.id) {
          try {
            const property_value = JSON.parse(last_location.value);
            install_location_prop.gps_location = property_value;
          } catch (e) {}
        }
      }

      const prop = {
        name: 'meta.install_location',
        value: {},
        objectID: device.id,
        objectType: Types.DEVICES
      };
      this.$refs.propEditor.save(install_location_prop, prop);
    },
    removeAllLayerFeatures(layer_name){
      this.map_layers.get(layer_name).forEach((feature) => {
        this.map_layers.get('markers').remove(feature);
      });
    }
    // setClusterStyles() {
    //   if (!this.districts.length) {
    //     this.clusterStyles = [
    //       [
    //         {
    //           url: config.icons.purpleCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ]
    //     ];
    //   } else {
    //     this.clusterStyles = [
    //       [
    //         {
    //           url: config.icons.purpleCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.blueCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.orangeCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.pinkCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.greenCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.redCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.lightblueCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.yellowCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.lightPinkCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.turquoiseCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.lightOrangeCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.lightPurpleCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.lightRedCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.darkGreenCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ],
    //       [
    //         {
    //           url: config.icons.greyCluster,
    //           textColor: '#fff',
    //           height: 24,
    //           width: 24
    //         }
    //       ]
    //     ];
    //   }
    // },
  },
  watch: {
    mapReady(ready) {
      if (!ready) return;
      this.syncSetMarkerInfo();
      // this.generateHeatmapLayers();
    },
    devices(){
      if (this.mapReady) {
        this.syncSetMarkerInfo();
      }
    },
    projectProperties(prev, next) {
      if (prev.length) {
        this.getProjectMapStyles();
      }
    },
    searchClickedChange(newVal) {
      this.searchActivated = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-settings {
  padding: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 55px;
  right: 50px;
  width: 160px;

  .v-text-field {
    padding-top: 5px !important;
  }

  .v-select__slot {
    background-color: #fff;
  }

  .v-select__selections {
    padding-left: 5px;
  }
}

.search-input {
  background-color: #fff;
  border-radius: 8px;
  font-family: Roboto;
  width: 350px;
  position: absolute !important;
  z-index: 2;
  height: 35px;
  top: 1px;
  right: 5px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0px !important;
}

.v-text-field__details {
  height: 0px !important;
}

.active {
  color: green !important;
}

.settings-btn-group {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  button {
    margin: 0 5px 0 0;
  }
}

.top-left-container {
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
  button {
    margin: 0 5px 0 0;
  }
}
.top-center-container {
  display: flex;
  position: absolute;
  top: 40px;
  left: 40%;
}

.editedDevice {
  box-shadow: 0 0 0 3px red;
  border-radius: 100%;
  position: absolute;
  opacity: 1 !important;
  z-index: 0;
}
.red-text {
  color: red;
  cursor: default;
}
// .btn-search {
//   width: 35px !important;
//   height: 35px !important;
//   background: #fff !important;
//   border-radius: 50% !important;
//   box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
//   color: #5a6376 !important;
// }

.background-white {
  background-color: white;
}

.color-green {
  color: green !important;
}

.bottom-right-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.bottom-right-selector {
  position: absolute;
  right: 14px;
  bottom: 110px;
}

.lower-bottom-right-selector {
  position: absolute;
  right: 14px;
  bottom: 70px;
}

.center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-right-selector-div {
  position: absolute;
  right: 70px;
  bottom: 110px;
}

.lower-bottom-right-selector-div {
  position: absolute;
  right: 70px;
  bottom: 70px;
}

.mobile-hide {
  @media (max-width: 480px) {
    display: none !important;
  }
}

.vue-map-container {
  height: 100% !important;
}

.unmarkedMAP {
  padding: 4px 20px;
  color: #ffc71e;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
  background-color: white;
}

.markedMAP {
  padding: 4px 20px;
  color: white;
  background-color: #ffc71e;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
}

.unmarkedGPS {
  padding: 4px 20px;
  color: green;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
  background-color: white;
}

.markedGPS {
  padding: 4px 20px;
  color: white;
  background-color: green;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
}

.unmarkedGIS {
  padding: 4px 20px;
  color: red;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
  background-color: white;
}

.markedGIS {
  padding: 4px 20px;
  color: white;
  background-color: red;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
}

.unmarkedAPP {
  padding: 4px 20px;
  color: purple;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
  background-color: white;
}

.markedAPP {
  padding: 4px 20px;
  color: white;
  background-color: purple;
  margin: 0 10px;
  border: 1px solid grey;
  font-weight: bold;
  font-size: 20px;
}
</style>

