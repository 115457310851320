import Vue from 'vue';
import Router from 'vue-router';

import vuex from '../store';
import Utils from '../modules/Utils';
import routes from './routes';
import { Route } from '@/modules/Types';
Vue.use(Router);

const flattenRoutes = [];
const addRoute = (data: Route) =>
  !data.path
    ? 0
    : flattenRoutes.push({
        path: data.path,
        name: data.name,
        meta: {
          ...data.meta,
          title: (data.meta || {}).title || data.menuTitle
        },
        component: () =>
          import(`@/../${data.module || 'src/views'}/${data.name || 'MapView'}.vue`)
      });

routes.forEach((route: Route) => {
  if (route.children) {
    route.children.forEach((subroute) =>
      addRoute({
        ...subroute,
        module: subroute.hasOwnProperty('module')
          ? subroute.module
          : route.module,
        meta: subroute.meta || route.meta
      })
    );
  } else {
    addRoute(route);
  }
});

const router = new Router({
  mode: 'history',
  routes: flattenRoutes,
  base: process.env.BASE_URL
});

router.beforeEach((to, from, next) => {
  // @ts-ignore
  const { isAuth, project } = vuex.state.User;
  // @ts-ignore
  const { lang } = vuex.state.Global;
  let nextPage = null;

  if (typeof to.meta.company === 'boolean') {
    if (to.meta.company && (!project || !project.id)) {
      nextPage = 'Projects';
    } else if (to.meta.company === false && project && project.id) {
      nextPage = 'MapView';
    }
  }

  if (typeof to.meta.auth === 'boolean') {
    if (to.meta.auth && !isAuth) {
      nextPage = 'Login';
      if (to.name === 'AlertManagmentByFilter') {
        localStorage.setItem('filtered_alerts_url', to.path);
      }
    } else if (to.meta.auth === false && isAuth) {
      nextPage = 'MapView';
    }else if (to.name === 'AlertManagmentByFilter' && (!project || project && project.id && to.path.split('/')[2] !== project.id)) {
      localStorage.setItem('filtered_alerts_url', to.path);
      nextPage = 'Projects';
    }else if (to.name === 'AlertManagmentByFilter' && project && project.id && localStorage.getItem('filtered_alerts_url')){
      localStorage.removeItem('filtered_alerts_url');
    }
  }

  if (!to.name && !to.path.includes('custom') && to.matched.findIndex((route) => route.path === '*') !== undefined) nextPage = 'Error404';

  if ((nextPage === 'Projects' && project || to.name === 'Projects' && project) && from.name !== 'Login'){
    vuex.commit('User/clearProject');
  }

  if (nextPage) {
    next({ name: nextPage });
  } else {
    next();
  }
});

router.afterEach((route) => {
  // @ts-ignore
  Utils.setDocumentTitle(route, vuex.state.User.project);
  Utils.setLoading(false);
});

export default router;
