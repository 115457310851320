





















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {User} from '@/store';
import {MapSettings, Global} from '@/store';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

@Component({
  components: {
    ConfirmDialog
  }
})
export default class CreateFilterView extends Vue {
  @Prop() filter;
  @Prop() name;
  @Prop() validateFilterName;

  @User.State('project') project;
  @MapSettings.State('userMapFilters') userMapFilters;
  @MapSettings.Action('updateMapFilter') updateMapFilter;
  @Global.Mutation('setEditFilterMode') setEditFilterMode;

  createDialog = false;

  async createFilter() {
    const filter = this.generateNewFilter();
    this.setEditFilterMode(false);
    await this.updateMapFilter({id: this.project.id, filter, path: filter.path });
    this.$emit('finish');
  }

  generateNewFilter(){
    const newFilter = Object.assign(this.filter);
    newFilter.name = this.name;
    if (!newFilter.groups.includes('all')){
      newFilter.circuit = [];
      newFilter.phase = [];
    }
    const path = this.userMapFilters.length ? this.getNextPath() : '/custom1';
    newFilter.path = path;
    newFilter.realPath = path;
    newFilter.meta = {
      auth: true,
      company: true
    };
    newFilter.parent = '/';
    newFilter.menuTitle = this.getMenuTitle();
    newFilter.cluster = this.name.includes('~');
    return newFilter;
  }

  getNextPath(){
    const existPaths = this.userMapFilters.map((filter) => +filter.path.replace('/custom', ''));
    const max = Math.max(...existPaths);
    return `/custom${max + 1}`;
  }

  getMenuTitle(){
    if (!this.name.includes('~')) return this.name;
   
    const start = this.name.indexOf('~');
    const end = this.name.indexOf('~', start + 1);
    return this.name.slice(end + 1);
  }
}
